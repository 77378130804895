import React from "react";
import Accordion from "react-bootstrap/Accordion";
import Table from "react-bootstrap/Table";
import latMap from "../assets/img/latviaMap.jpeg";

type Props = {};

const LatComponent = (props: Props) => {
  return (
    <>
      <div>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>About Latvia </Accordion.Header>
            <Accordion.Body>
              <div className="d-md-flex flex-sm-column flex-md-row gap-2">
                <div className="col-md-6 mb-2">
                  <img
                    src={latMap}
                    className="mt-2 mx-4 img-fluid object-cover"
                    style={{ width: "95%" }}
                  />
                </div>
                <div className="col-md-6">
                  <Table striped bordered hover size="sm">
                    <tbody>
                      <tr>
                        <td>Location</td>
                        <td> Latvia is a Schengen country in Europe. </td>
                      </tr>
                      <tr>
                        <td>Capital</td>
                        <td>Riga</td>
                      </tr>
                      <tr>
                        <td>Currency</td>
                        <td>Euro (1 Euro = Rs 90 approx.) </td>
                      </tr>
                      <tr>
                        <td>Neighbouring Countries</td>
                        <td>Estonia, Lithuania.</td>
                      </tr>
                      <tr>
                        <td>Languages Spoken</td>
                        <td>English & Latvian</td>
                      </tr>
                      <tr>
                        <td>Cost of Living</td>
                        <td>
                          400 – 500 Euro/ Month (living expenses vary person to
                          person)
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1">
            <Accordion.Header>Why Study in Latvia - USP</Accordion.Header>
            <Accordion.Body>
              <div>
                <Table striped bordered hover size="sm">
                  <tbody>
                    <tr>
                      <td>Part Time</td>
                      <td>
                        <ul>
                          <li>Bachelor – 20 Hours/ Week</li>
                          <li>Master - Full Time</li>
                          <li>PhD - Full Time</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>Post Study Visa</td>
                      <td>
                        <ul>
                          <li>Bachelor - 6 Months </li>
                          <li>Master - 9 Months</li>
                          <li>PhD - 9 Months</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>Show Money</td>
                      <td>
                        8400 Euro i.e. Rs 8 Lacs Approx. Master (Parents/
                        Student Account)
                      </td>
                    </tr>
                    <tr>
                      <td>Wages</td>
                      <td>
                        5 Euro/ hour, depending on the type of job and the
                        student's skills and experience Earning e.g. Minimum
                        Wage (Euro 5/hour) X 20 hours/week = 100 Euro x 4 weeks
                        = 400 Euro per month, 400 Euro X 12 Months = 4,800 Euro/
                        Year (i.e. Rs 4,40 Lacs approx.)
                      </td>
                    </tr>
                    <tr>
                      <td>Types of part time jobs</td>
                      <td>Restaurants, Delivery jobs etc.</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="2">
            <Accordion.Header>Popular Universities</Accordion.Header>
            <Accordion.Body>
              <div>
                <ul className="list-unstyled">
                  <li>
                    Latvia University of Life Sciences and Technologies
                    <strong>(Partner)</strong>
                  </li>
                  <li>
                    Riga Technical University
                    <strong>(Premium)</strong>
                  </li>
                  <li>
                    Turiba University
                    <strong>(Premium)</strong>
                  </li>
                  <li>
                    University of Latvia
                    <strong>(Premium)</strong>
                  </li>
                  <li>
                    Riga Stradins University
                    <strong>(Premium)</strong>
                  </li>
                  <li>
                    RISEBA University of Applied Sciences
                    <strong>(Premium)</strong>
                  </li>
                  <li>
                    TSI <strong>(Indirect)</strong>
                  </li>
                  <li>
                    Eka University of Applied Sciences{" "}
                    <strong>(Premium) </strong>
                  </li>
                </ul>
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="3">
            <Accordion.Header>Popular Programs</Accordion.Header>
            <Accordion.Body>
              <div>
                <Table striped bordered hover size="sm">
                  <thead>
                    <tr>
                      <th>Level</th>
                      <th>Programs</th>
                      <th>Duration</th>
                      <th>Tuition Fee</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Bachelor</td>
                      <td>
                        Business Administration, Hospitality, Computer
                        Engineering/Science, Software Engineering, Mechanical
                        Engineering, Civil Engineering, Medicine, Nursing,
                        Dentistry and more.
                      </td>
                      <td>3- 4 Years</td>
                      <td>2,500 - 6,000 Euro/ Year</td>
                    </tr>
                    <tr>
                      <td>Master</td>
                      <td>
                        MBA, Computer Engineering, Mechanical Engineering, Civil
                        Engineering, Logistics and Supply Chain Management,
                        Health Management etc.
                      </td>
                      <td>1-2 Years</td>
                      <td>3,000 - 6,000 Euro/ Year</td>
                    </tr>
                    <tr>
                      <td>PhD</td>
                      <td>
                        Business Administration, Law Science, Communication
                        Management, Civil, Mechanical Engineering etc
                      </td>
                      <td>3 Years</td>
                      <td>3,000 - 10,000 Euro/ Year</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="4">
            <Accordion.Header>Admission</Accordion.Header>
            <Accordion.Body>
              <div>
                <Table striped bordered hover size="sm">
                  <thead>
                    <tr>
                      <th>Level</th>
                      <th>Intake</th>
                      <th>Deadline</th>
                      <th>Eligibility Criteria</th>
                      <th>Age Limit</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Bachelor</td>
                      <td>Sep/ Feb</td>
                      <td>Jun/Oct</td>
                      <td>Class 12th with 60%</td>
                      <td>23</td>
                    </tr>
                    <tr>
                      <td>Master</td>
                      <td>Sep/ Feb</td>
                      <td>Jun/Oct</td>
                      <td>3-4 Years' Bachelor with 60%</td>
                      <td>30-35</td>
                    </tr>
                    <tr>
                      <td>PhD</td>
                      <td>Sep/ Feb</td>
                      <td>Jun/Oct</td>
                      <td>Master (relevant field) with 60%</td>
                      <td>Flexible (40 around)</td>
                    </tr>
                  </tbody>
                </Table>
                <span>
                  <strong>Note: </strong>Please pay attention to university
                  specific admission criteria.
                </span>
                <p>
                  <strong>Language Proficiency Required: </strong>IELTS
                  (equivalent to 5.5-6.0)/ PTE/ MOI
                </p>
                <p>
                  <strong>
                    Documents Required for Admission Application:{" "}
                  </strong>
                  <li>Application Fee – 150-200 Euro Approx.</li>
                  <li>Academic Marksheets and Certificates</li>
                  <li>
                    CV, Motivation Letter, Letters of Recommendation (if
                    applicable)
                  </li>
                  <li>Language Proficiency</li>
                </p>
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="5">
            <Accordion.Header>Application Processing Time</Accordion.Header>
            <Accordion.Body>
              <div>
                <strong>Step 1</strong> - Application Submitted <br />
                <strong>Step 2</strong> - Application Fee Paid
                <br />
                <strong>Step 3</strong> - University Conducts Entrance Test/ and
                Interview <br />
                <strong>Step 4</strong> - Documents Sent to AIC by University{" "}
                <br />
                <strong>Step 5</strong> - Positive AIC Decision Received (After
                4-8 weeks)
                <br />
                <strong>Step 6</strong> - University issues Acceptance Letter
                and Agreement <br />
                <strong>Step 7</strong> - Tuition Fee Paid <br />
                <strong>Step 8</strong> - Invitation Letter Received <br />
                <strong>Step 9</strong> - Hello Verification Applied <br />
                <strong>Step 10</strong> - Visa File Submitted at VFS
                <br />
                <strong>Step 11</strong> - Visa Decision (2-4 Weeks)
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="6">
            <Accordion.Header>Student Visa Requirements </Accordion.Header>
            <Accordion.Body>
              <div>
                <span>
                  <strong>Funds Required</strong> - 8400 Euro in Savings Account
                  or FTS (if applicable as per university)
                </span>
                <h5>Documents Required for Visa:</h5>
                <ul>
                  <li>Invitation Letter from University</li>
                  <li>Study Agreement</li>
                  <li>Acceptance Letter</li>
                  <li>AIC Certificate</li>
                  <li>Accommodation Proof</li>
                  <li>
                    Bank Certificate of Sponsor/ FTS Agreement with Receipt
                  </li>
                  <li>3 Months Savings Account Statement of Sponsor</li>
                  <li> 3 Years ITRs of Sponsor</li>
                  <li> Sponsorship Affidavit</li>
                  <li>Previous Educational Documents </li>
                  <li>Travel Medical Insurance</li>
                  <li>Itinerary</li>
                  <li>Hello Verification Confirmation Slip</li>
                  <li>PCC (Apostle).</li>
                </ul>
                <span>
                  <strong> Processing Time:</strong> 2-3 Weeks after Application
                  Submitted.
                </span>
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="7">
            <Accordion.Header>Important Notes</Accordion.Header>
            <Accordion.Body>
              <div>
                <ul>
                  <li>
                    <strong>Academic Information Centre</strong> (AIC) is a
                    non-profit institution, foundation in 1994 by the Ministry
                    of Education in Latvia. The AIC assesses and recognizes
                    foreign educational qualifications, ensuring they meet
                    Latvian standards. Latvian universities apply for AIC
                    assessment on behalf of their students, and each university
                    must obtain AIC approval before issuing an Acceptance Letter
                    to students.
                  </li>
                  <li>
                    <strong>Hello Verification </strong>– It is valid till 6
                    months
                  </li>
                  <li>
                    <strong>PCC </strong>– Is valid upto 6 months only
                  </li>
                  <li>
                    <strong> Who can Sponsor</strong> – Parents, Student
                    themselves or Siblings
                  </li>
                  <li>
                    <strong>Minimum Income Required in ITRs</strong> – 4,50 Lacs
                    and above.
                  </li>
                  <li>
                    <strong>What is FTS</strong> – Funds Transfer Scheme, is a
                    financial arrangement used by certain universities, such as
                    Riga Technical University, Turiba University, RISEBA
                    University of Applied Sciences, and the University of
                    Latvia. This scheme allows students to transfer LIVING
                    Expenses to the university account.
                  </li>
                  <li>
                    <strong>What is Benefit of FTS</strong> – The primary
                    benefit of the FTS is that it simplifies the financial
                    documentation required for students. Under this scheme,
                    students are not required to submit Income Tax Returns
                    (ITRs) or provide a three-month savings account statement
                    showing a maintained balance. This is particularly
                    advantageous for students who may not have access to a
                    sponsor’s ITRs or substantial old funds.
                  </li>
                </ul>
                <p>
                  Student Need to add their Savings Account Statement in Visa
                  File - In case the student is being sponsored or showing FTS,
                  it is recommended that they show their 1-month savings account
                  statement in the visa application.
                </p>
                Before leaving India after Visa Approval, student must get a
                Bank Certificate issued in his/ her name stating that “they have
                8400 Euro in his account”. This Bank Certificate will be used in
                their TRP application in Latvia.
                <p>
                  <strong>
                    Bank Certificate must contain following information:
                  </strong>
                  <ul>
                    <li>Full Name</li>
                    <li>Date of Birth</li>
                    <li>Passport Number</li>
                    <li>Currency in INR and Euro</li>
                  </ul>
                </p>
                <p>
                  <strong>Documents Required for Hello Verification</strong>
                  <ol>
                    <li>
                      Class 12th/ or Bachelor Degree (i.e. Highest Educational
                      Certificate with APOSTLE)
                    </li>
                    <li>
                      3 Year ITRs / or FTS Agreement (whichever applicable)
                    </li>
                    <li>Student Passport Copy (Front and Back)</li>
                    <li>Student PAN / Voter ID / Driving Licens</li>
                    <li>Authorization Letter for Hello Verify </li>
                  </ol>
                </p>
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="8">
            <Accordion.Header>
              After you Reach in Latvia post Visa Approval
            </Accordion.Header>
            <Accordion.Body>
              <div>
                <div className="py-2">
                  <span>
                    After arrival in Latvia, student needs to apply for
                    <strong> Temporary Residence permit card (TRP).</strong> Here
                    is the checklist of documents required.
                  </span>
                  <ul>
                    <li>
                      <strong>TRP Application Form–</strong> You need to submit
                      TRP application at Immigration (form will be provided by
                      the University).
                    </li>
                    <li>
                      <strong>Study Agreement</strong>
                    </li>
                    <li>
                      <strong>Rental Agreement/ Accommodation Proof</strong>
                    </li>
                    <li>
                      <strong>Passport Copy</strong> of student
                    </li>
                    <li>
                      <strong>Bank Certificate</strong> in the name of Student
                      showing 8400 Euro
                    </li>
                    <li>
                      <strong>PCC</strong> with Apostle
                    </li>
                    <li>
                      <strong>Payment proof of TRP procssing –</strong> 30 days
                      (70 Euro), 10 days (200 Euro) or 5 days (400 Euro).
                    </li>
                    <li>
                      <strong>Chest X-Ray</strong> (15-20 Euro)
                    </li>
                    <li>
                      <strong>Biometric</strong>
                    </li>
                    <li>
                      <strong>Latvian Insurance - </strong>(costs around 50
                      Euro)
                    </li>
                  </ul>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="9">
            <Accordion.Header>Visa – Dependent</Accordion.Header>
            <Accordion.Body>
              <div>
                <div className="gap-y-2">
                  <span style={{ color: "red" }}>
                    Only if the University supports and issues an invitation
                    letter for Dependent, the student can invite their Spouse
                    and Kid on TRP visa in Latvia after completion of 1 semester
                    in Latvia.
                  </span>
                  <h6>Documents Required:</h6>
                  <ul>
                    <li>Student TRP Card</li>
                    <li>Dependent Passport Copy</li>
                    <li>Marriage Certificate with Apostle (spouse)</li>
                    <li>PCC (Apostle)</li>
                    <li>Bank Certificate (Dependent A/c – 8400 Euro)</li>
                    <li>Internationally Valid Debit Card Copy</li>
                    <li>Educational and Experience Documents</li>
                  </ul>

                  <span>
                    <strong style={{ color: "red" }}>Note –</strong> Bank
                    Certificate must contain following information:
                  </span>

                  <ul>
                    <li>Passport Number</li>
                    <li>Date of Birth</li>
                    <li>Currency in INR and Euro</li>
                  </ul>

                  <span>
                    <strong style={{ color: "red" }}>
                      Spouse CANNOT work in Latvia.
                    </strong>
                  </span>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          
          <Accordion.Item eventKey="10">
            <Accordion.Header>How to get Permanent Residency</Accordion.Header>
            <Accordion.Body>
              <div>
                <div className="gap-y-2">
                  <ul>
                    <li>
                      To obtain a Latvian Residence Permit, an individual must
                      reside in Latvia for five years and pass a language test.
                    </li>
                    <li>
                      The duration of the study period is counted as half; for
                      instance, four years of study will be considered
                      equivalent to two years of residency.
                    </li>
                    <li>
                      Upon completing their studies, an individual can convert
                      their status to a Work Permit and continue staying in
                      Latvia.
                    </li>
                  </ul>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </>
  );
};

export default LatComponent;
