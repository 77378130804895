import React from "react";

export default function ContactInformation(props: { title: string }) {
  return (
    <>
      <div className="container bg-light2 py-3" style={{ marginTop: "9rem" }}>
        <div className="section-head col-sm-12 mb-0">
          <h4>
            <span>Check Program</span> Availability
          </h4>
        </div>
      
        <iframe
          src="https://docs.google.com/spreadsheets/d/e/2PACX-1vTJfPSOgWghoQIpdt_s-c4K50CE2B8Gnszf6TJHwQXJEW1qrxEJKahAQO_w_5Q2vw/pubhtml?widget=true&amp;headers=false"
          width="100%"
          height="850px"
        ></iframe>
      </div>
    </>
  );
}
