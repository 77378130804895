import React from 'react'
import ukmap from '../assets/newfolder/ukmap.png'

export default function Mapuk() {
  return (
    <>
      <div className="accordion" id="accordionExample">
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingOne">
      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
      Map of United Kingdom
      </button>
    </h2>
    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
    <div className="accordion-body">
    <img src={ukmap} alt="" />   
    </div>
    </div>
  </div>

  <div className="accordion-item">
    <h2 className="accordion-header" id="headingTwo">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
      General Weather Information
      </button>
    </h2>
    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <h5>Variable climate.</h5>
        <p>Unpredictable weather. In general, the summers are warm and winters see snowfall. Summers are cooler than those of India</p>

        <h5>England</h5>
        <p>Sunny weather throughout the year.</p>
        <p>Occasionally strong winds.</p>
        <p>Rains are unpredictable and it could rain at any time</p>
        <p>Average temperature Summers around 25°C to 15° C; Winters around 7°C to 0°C. London,</p>
        <p>Manchester, Birmingham are popular cities.</p>

        <h5>Wales</h5>
        <p>Rain fall between October and January.</p>
        <p>Days in winters are shorter and the region experiences snow.</p>
        <p>Summers are warmer.</p>
        <p>Average temperature – Summers – around 23°C to 12°C; Winters around 8°C to 1°C.</p>
        <p>Cardiff, Newport, Swansea are popular cities.</p>

        <h5>Scotland</h5>
        <p>Western parts get a lot more rains than the eastern Parts.</p>
        <p>Temperate and unpredictable climate.</p>
        <p>Average temperature – Summers around 19°C to 10°</p>
        <p>C: Winters around 6°C to -1°C.</p>
        <p>Gugh, Glasgow, and Aberdeen are the major.</p>

        <h5>Northern Ireland</h5>
        <p>Relatively mild winters and cool summers. Northern Ireland is cloudier and gets less sunshine comparatively</p>
        <p>Average temperature – Summers – around 19°C to 10°C; Winters around 8 C to 0°C.</p>
        <p>Belfast, Lisburn, Newry are popular cities.</p>

      </div>
    </div>
  </div>

  </div>
    </>
  )
}
