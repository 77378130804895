import React from "react";
import { Link } from "react-router-dom";
import canada from "../assets/flags/Canada.png";
import usa from "../assets/flags/US.png";
import uk from "../assets/flags/UK.png";
import australia from "../assets/flags/Australia.png";
import germany from "../assets/flags/Germany.jpg";
import singapore from "../assets/flags/singapore.jpg";
import ireland from "../assets/flags/ireland.png";
import finland from "../assets/flags/finland.png";
import latvia from "../assets/flags/latvia.png";
import denmark from "../assets/flags/denmark.png";
import sweden from "../assets/flags/sweden.jpg";

import ielts from "../assets/courselogo/ieltslogo.png";
import toefl from "../assets/courselogo/toefllogo.png";
import pte from "../assets/courselogo/ptelogo.png";
import gre from "../assets/courselogo/grelogo2.png";
import gmat from "../assets/courselogo/gmatlogo2.png";
import duolingo from "../assets/courselogo/duolingologo.png";
import sat from "../assets/courselogo/satlogo.png";

import sop from "../assets/courselogo/sopcard.png";
import lor from "../assets/courselogo/lorcard.png";
import resume from "../assets/courselogo/resumecard.png";

export default function Homecentersec() {
  return (
    <>
      <div className="testprepsec2 mt-3 wow fadeInUp col-md-12">
        <ul className="nav nav-pills nav-justified">
          <li className="nav-item">
            <a
              className="nav-link active testpreptab"
              data-bs-toggle="pill"
              href="#home"
            >
              <i className="fa fa-globe" aria-hidden="true"></i> Country
              Couselling
            </a>
          </li>

          <li className="nav-item">
            <a
              className="nav-link testpreptab"
              data-bs-toggle="pill"
              href="#menu2"
            >
              <i className="fa fa-user" aria-hidden="true"></i> Admission Test{" "}
            </a>
          </li>

          <li className="nav-item">
            <a
              className="nav-link testpreptab"
              data-bs-toggle="pill"
              href="#menu3"
            >
              <i className="fa fa-book" aria-hidden="true"></i> Documentation{" "}
            </a>
          </li>
        </ul>

        <div className="tab-content">
          <div className="tab-pane active" id="home">
            <div className="container-fluid pt-3">
              <div className="d-flex row">
                <div className="col-md-3 country-card">
                  <div className="countrycardinner p-2">
                    <div className="card-head text-center mt-3">
                      <img src={canada} alt="" style={{ width: "100px" }} />
                      <hr />
                      <h5 className="text-primary">Canada</h5>
                      <h6>Why Study In Canada</h6>
                    </div>

                    <ul>
                      <li className="custom-p2">
                        Qualifications valued around the World.
                      </li>
                      <li className="custom-p2">Affordable Education.</li>
                      <li className="custom-p2">Multicultural Society.</li>
                      <li className="custom-p2">
                        Possibility of paid Internships while studying.
                      </li>
                      <li className="custom-p2">
                        Post Study Work Visa and good Job opportunities after
                        completion of studies.
                      </li>
                      <li className="custom-p2">
                        Possibility of Permanent Residency.
                      </li>
                      <li className="custom-p2">
                        Tuition Fee: 12,000-55,000 CAD / year
                      </li>
                      <li className="custom-p2">
                        Living Expenses: 10,000 to 15,000 CAD / year
                      </li>
                      <li className="custom-p2">
                        Post Study Work Permit: upto 3 years depending upon
                        duration of the program
                      </li>
                      <li className="custom-p2">
                        Intakes: January, May, September
                      </li>
                    </ul>

                    <div className="cardfooter text-center mb-3">
                      <button className="btn btn-sm btn-success">
                        <Link className="text-light" to="/canada">
                          Read More
                        </Link>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 country-card">
                  <div className="countrycardinner p-2">
                    <div className="card-head text-center mt-3">
                      <img src={uk} alt="" style={{ width: "100px" }} />
                      <hr />
                      <h5 className="text-primary">United Kingdom</h5>
                      <h6>Why Study In UK</h6>
                    </div>

                    <ul>
                      <li className="custom-p2">
                        International Recognised Universities and Qualifications
                      </li>
                      <li className="custom-p2">Quality Education</li>
                      <li className="custom-p2">
                        Strong Research Infrastructure
                      </li>
                      <li className="custom-p2">Shorter Duration of Courses</li>
                      <li className="custom-p2">
                        Degree programs available in every field
                      </li>
                      <li className="custom-p2">Work While You Study</li>
                      <li className="custom-p2">Work Permit Post-Study</li>
                      <li className="custom-p2">
                        Scholarship and Financial Support
                      </li>
                      <li className="custom-p2">
                        Tuition Fee: 12,000 – 40,000 Pound / year
                      </li>
                      <li className="custom-p2">
                        Living Expenses: London – 12,006 Pound & Outside London
                        – 9207 Pound
                      </li>
                      <li className="custom-p2">
                        Post Study Work Permit: 2 years
                      </li>
                      <li className="custom-p2">Intakes: January, September</li>
                    </ul>

                    <div className="cardfooter text-center mb-3">
                      <button className="btn btn-sm btn-success">
                        <Link className="text-light" to="/uk">
                          Read More
                        </Link>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 country-card">
                  <div className="countrycardinner p-2">
                    <div className="card-head text-center mt-3 px-2">
                      <img src={usa} alt="" style={{ width: "100px" }} />
                      <hr />
                      <h5 className="text-primary">United States of America</h5>
                      <h6>Why Study In United States of America</h6>
                    </div>

                    <ul>
                      <li className="custom-p2">Worldwide Recognition</li>
                      <li className="custom-p2">Academic Flexibility</li>
                      <li className="custom-p2">Funding Opportunities</li>
                      <li className="custom-p2">Research & Technology</li>
                      <li className="custom-p2">Global Opportunities</li>
                      <li className="custom-p2">Enhanced Campus Life</li>
                      <li className="custom-p2">
                        State of the Art Infrastructure
                      </li>
                      <li className="custom-p2">World Class Faculty</li>
                      <li className="custom-p2">Well Rounded</li>
                      <li className="custom-p2">
                        Tuition Fee: 10,000 – 32,000 USD
                      </li>
                      <li className="custom-p2">
                        Living Expenses: 9,000 – 15,000 USD
                      </li>
                      <li className="custom-p2">
                        Post Study Work Permit: 1 to 3 years depending upon
                        program
                      </li>
                      <li className="custom-p2">Intakes: January, September</li>
                    </ul>

                    <div className="cardfooter text-center mb-3">
                      <button className="btn btn-sm btn-success">
                        <Link className="text-light" to="/usa">
                          Read More
                        </Link>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 country-card">
                  <div className="countrycardinner p-2">
                    <div className="card-head text-center mt-3 px-2">
                      <img src={australia} alt="" style={{ width: "100px" }} />
                      <hr />
                      <h5 className="text-primary">Australia</h5>
                      <h6>Why Study In Australia</h6>
                    </div>

                    <ul>
                      <li className="custom-p2">World Class Education</li>
                      <li className="custom-p2">Global Recognition</li>
                      <li className="custom-p2">Quality Assurance</li>
                      <li className="custom-p2">
                        International Students Rate Australia Highly
                      </li>
                      <li className="custom-p2">
                        Australia – A Research Intensive Country
                      </li>
                      <li className="custom-p2">Learning Environment</li>
                      <li className="custom-p2">Post Study Work Options</li>
                      <li className="custom-p2">
                        Tuition Fee: 23,000 – 50,000 AUD
                      </li>
                      <li className="custom-p2">
                        Living Expenses: 21,040 AUD / year
                      </li>
                      <li className="custom-p2">
                        Post Study Work Permit: 2-4 years (varies as per region
                        of study & qualification)
                      </li>
                      <li className="custom-p2">
                        Intakes: February, July, November
                      </li>
                    </ul>

                    <div className="cardfooter text-center mb-3">
                      <button className="btn btn-sm btn-success">
                        <Link className="text-light" to="/australia">
                          Read More
                        </Link>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 country-card">
                  <div className="countrycardinner p-2">
                    <div className="card-head text-center mt-3 px-2">
                      <img
                        src={germany}
                        className="rounded-3"
                        style={{ width: "120px" }}
                      />
                      <hr />
                      <h5 className="text-primary">Germany</h5>
                      <h6>Why Study In Germany</h6>
                    </div>

                    <ul>
                      <li className="custom-p2">
                        18 Months stay back after the completion of the study.
                      </li>
                      <li className="custom-p2">
                        Part-Time jobs allowed 20 hours per week.
                      </li>
                      <li className="custom-p2">Schengen Visa.</li>
                      <li className="custom-p2">
                        Quality education from State Funded Universities with
                        low Tuition Fees
                      </li>
                      <li className="custom-p2">
                        Scientifically oriented study in a wide range
                      </li>
                      <li className="custom-p2">
                        Tuition Fee: Free Education 13,000 Euro to 20000 Euros /
                        year
                      </li>
                      <li className="custom-p2">
                        Living Expenses: 9000 Euros (750 Euros / Month)
                      </li>
                      <li className="custom-p2">
                        Post Study Work Permit Duration: 18 Months
                      </li>
                      <li className="custom-p2">Intakes: January, September</li>
                    </ul>

                    <div className="cardfooter text-center mb-3">
                      <button className="btn btn-sm btn-success">
                        <Link className="text-light" to="/germany">
                          Read More
                        </Link>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 country-card">
                  <div className="countrycardinner p-2">
                    <div className="card-head text-center mt-3 px-2">
                      <img
                        src={ireland}
                        className="rounded-3"
                        style={{ width: "120px" }}
                      />
                      <hr />
                      <h5 className="text-primary">Ireland</h5>
                      <h6>Why Study In Ireland</h6>
                    </div>

                    <ul>
                      <li className="custom-p2">English speaking country.</li>
                      <li className="custom-p2">
                        Low cost of tuition fees & accommodation.
                      </li>
                      <li className="custom-p2">
                        Internationally recognized qualifications & extensive
                        choice of courses.
                      </li>
                      <li className="custom-p2">
                        European Headquarters of many IT & Pharmaceutical
                        companies.
                      </li>
                      <li className="custom-p2">Masters degree of 1 year.</li>
                      <li className="custom-p2">
                        Post study work visa of 2 years for Masters.
                      </li>
                      <li className="custom-p2">
                        Chance to get indefinite right to stay (similar to
                        Permanent Residency).
                      </li>
                      <li className="custom-p2">
                        Tuition Fee: 10000 – 22000 Euros / Year
                      </li>
                      <li className="custom-p2">
                        Living Expenses: 10000 Euros / Year (834 Euros/Month)
                      </li>
                      <li className="custom-p2">
                        Post Study Work Permit Duration: 1 Year to 2 Year
                      </li>
                      <li className="custom-p2">
                        Intakes: January, May, September
                      </li>
                    </ul>

                    <div className="cardfooter text-center mb-3">
                      <button className="btn btn-sm btn-success">
                        <Link className="text-light" to="/ireland">
                          Read More
                        </Link>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 country-card">
                  <div className="countrycardinner p-2">
                    <div className="card-head text-center mt-3 px-2">
                      <img
                        src={singapore}
                        className="rounded-3 shadow"
                        style={{ width: "120px" }}
                      />
                      <hr />
                      <h5 className="text-primary">Singapore</h5>
                      <h6>Why Study In Singapore</h6>
                    </div>

                    <ul>
                      <li className="custom-p2">Top-notch education system</li>
                      <li className="custom-p2">Education Grants</li>
                      <li className="custom-p2">English speaking country</li>
                      <li className="custom-p2">
                        Lowest Crime rate – Safest and clean country
                      </li>
                      <li className="custom-p2">Affordable Cost</li>
                      <li className="custom-p2">
                        Immersion into Asian Century
                      </li>
                    </ul>

                    <div className="cardfooter text-center mb-3">
                      <button className="btn btn-sm btn-success">
                        <Link className="text-light" to="/singapore">
                          Read More
                        </Link>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 country-card">
                  <div className="countrycardinner p-2">
                    <div className="card-head text-center mt-3 px-2">
                      <img
                        src={finland}
                        className="rounded-3 shadow"
                        style={{ width: "120px" }}
                      />
                      <hr />
                      <h5 className="text-primary">Finland</h5>
                      <h6>Why Study In Finland</h6>
                    </div>

                    <ul>
                      <li className="custom-p2">Top-notch education system</li>
                      <li className="custom-p2">Gap Accepted.</li>
                      <li className="custom-p2">Schengen Visa.</li>
                      <li className="custom-p2">Education Grants</li>
                      <li className="custom-p2">English speaking country</li>
                      <li className="custom-p2">
                        Lowest Crime rate – Safest and clean country
                      </li>
                      <li className="custom-p2">
                        Good Job Opportunities for Students.
                      </li>
                      <li className="custom-p2">Affordable Cost</li>
                      <li className="custom-p2">
                        Tuition Fee: 9,000 to 11,000 Euro/ Year Approx.
                      </li>
                      <li className="custom-p2">
                        Programs Available: Bachelor, Master and Tailor-Made
                        Diploma.
                      </li>
                      <li className="custom-p2">
                        Immersion into Asian Century
                      </li>
                      <li className="custom-p2">Intakes: January, September</li>
                    </ul>

                    <div className="cardfooter text-center mb-3">
                      <button className="btn btn-sm btn-success">
                        <Link className="text-light" to="/finland">
                          Read More
                        </Link>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 country-card">
                  <div className="countrycardinner p-2">
                    <div className="card-head text-center mt-3 px-2">
                      <img
                        src={latvia}
                        className="rounded-3 shadow"
                        style={{ width: "120px" }}
                      />
                      <hr />
                      <h5 className="text-primary">Latvia</h5>
                      <h6>Why Study In Latvia</h6>
                    </div>

                    <ul>
                      <li className="custom-p2">Top-notch education system</li>

                      <li className="custom-p2">Schengen Visa.</li>
                      <li className="custom-p2">Education Grants</li>
                      <li className="custom-p2">English speaking country</li>
                      <li className="custom-p2">
                        Lowest Crime rate – Safest and clean country
                      </li>
                      <li className="custom-p2">
                        Good Job Opportunities for Students.
                      </li>
                      <li className="custom-p2">Affordable Cost</li>
                      <li className="custom-p2">
                        Tuition Fee: 2500 to 10,000 Euro/ Year Approx.
                      </li>
                      <li className="custom-p2">
                        Programs Available: Bachelor, Master and Tailor-Made
                        Diploma.
                      </li>
                      <li className="custom-p2">
                        Immersion into Asian Century
                      </li>
                      <li className="custom-p2">Intakes: January, August</li>
                    </ul>

                    <div className="cardfooter text-center mb-3">
                      <button className="btn btn-sm btn-success">
                        <Link className="text-light" to="/latvia">
                          Read More
                        </Link>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 country-card">
                  <div className="countrycardinner p-2">
                    <div className="card-head text-center mt-3 px-2">
                      <img
                        src={denmark}
                        className="rounded-3 shadow"
                        style={{ width: "120px" }}
                      />
                      <hr />
                      <h5 className="text-primary">Denmark</h5>
                      <h6>Why Study In Denmark</h6>
                    </div>

                    <ul>
                      <li className="custom-p2">Top-notch education system</li>

                      <li className="custom-p2">Schengen Visa.</li>
                      <li className="custom-p2">Education Grants</li>
                      <li className="custom-p2">English speaking country</li>
                      <li className="custom-p2">
                        Lowest Crime rate – Safest and clean country
                      </li>
                      <li className="custom-p2">
                        Good Job Opportunities for Students.
                      </li>

                      <li className="custom-p2">
                        Cost of Living: 5000 – 7000 DKK/ Month (living expenses
                        vary person to person)
                      </li>
                      <li className="custom-p2">
                        Tuition Fee: DKK 80,000 to 120,000/ Year Approx.
                      </li>
                      <li className="custom-p2">
                        Programs Available: Bachelor and Master
                      </li>
                      <li className="custom-p2">
                        Visa processing time - 2 to 3 months after Application
                        Submitted.
                      </li>
                      <li className="custom-p2">
                        Intakes: September, February
                      </li>
                    </ul>

                    <div className="cardfooter text-center mb-3">
                      <button className="btn btn-sm btn-success">
                        <Link className="text-light" to="/denmark">
                          Read More
                        </Link>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 country-card">
                  <div className="countrycardinner p-2">
                    <div className="card-head text-center mt-3 px-2">
                      <img
                        src={sweden}
                        className="rounded-3 shadow"
                        style={{ width: "120px" }}
                      />
                      <hr />
                      <h5 className="text-primary">Sweden</h5>
                      <h6>Why Study In Sweden</h6>
                    </div>

                    <ul>
                      <li className="custom-p2">Top-notch education system</li>

                      <li className="custom-p2">Schengen Visa.</li>
                      <li className="custom-p2">Education Grants</li>
                      <li className="custom-p2">No Language Barrier</li>
                      <li className="custom-p2">
                        Beautiful Nature & healthy Environment
                      </li>
                      <li className="custom-p2">
                        Swedish & English speaking country
                      </li>
                      <li className="custom-p2">
                        Lowest Crime rate – Safest and clean country
                      </li>
                      <li className="custom-p2">
                        Good Job Opportunities for Students.
                      </li>

                      <li className="custom-p2">
                        Tuition fees: SEK 1,15,000 to SEK 1,50,000
                      </li>
                      <li className="custom-p2">
                        Programs Available: Bachelor & Master
                      </li>
                      <li className="custom-p2">Intakes: September </li>
                    </ul>

                    <div className="cardfooter text-center mb-3">
                      <button className="btn btn-sm btn-success">
                        <Link className="text-light" to="/sweden">
                          Read More
                        </Link>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="tab-pane fade" id="menu2">
            <div className="container-fluid pt-3">
              <div className="d-flex row">
                <div className="col-md-3 country-card">
                  <div className="countrycardinner p-2">
                    <div className="card-head text-center mt-3">
                      <img src={ielts} alt="" style={{ width: "150px" }} />
                      <hr />
                      <h5 className="text-primary">IELTS</h5>
                      <h6>
                        What is the IELTS test format and how long will it take
                      </h6>
                    </div>

                    <p className="custom-p2 my-3">
                      The IELTS test has four sections Listening (30 minutes),
                      Reading (60 minutes), Writing (60 minutes) and Speaking
                      (11-14 minutes). The total test time is 2 hours and 45
                      minutes.
                    </p>

                    <div className="cardfooter text-center mb-3">
                      <button className="btn btn-sm btn-success">
                        Read More
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 country-card">
                  <div className="countrycardinner p-2">
                    <div className="card-head text-center mt-3">
                      <img src={pte} alt="" style={{ width: "150px" }} />
                      <hr />
                      <h5 className="text-primary">PTE</h5>
                      <h6>PTE Academic: the computer-based English test</h6>
                    </div>

                    <p className="custom-p2 my-3">
                      PTE Academic measures your English speaking, listening,
                      reading, and writing skills in a single, short test.
                    </p>

                    <div className="cardfooter text-center mb-3">
                      <button className="btn btn-sm btn-success">
                        <Link className="text-light" to="/pte">
                          Read More
                        </Link>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 country-card mb-4">
                  <div className="countrycardinner p-2">
                    <div className="card-head text-center mt-3 px-2">
                      <img src={duolingo} alt="" style={{ width: "150px" }} />
                      <hr />
                      <h5 className="text-primary">Duolingo</h5>
                      <h6>Duolingo Test Pattern</h6>
                    </div>

                    <p className="custom-p2 my-3">
                      The Duolingo English test pattern is planned and designed
                      using Data Forensics and Artificial Intelligence
                      technology to maintain the clarity of the test. The test
                      lasts for one hour and is administered online in one go.{" "}
                    </p>

                    <div className="cardfooter text-center mb-3">
                      <button className="btn btn-sm btn-success">
                        <Link className="text-light" to="/duolingo">
                          Read More
                        </Link>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 country-card">
                  <div className="countrycardinner p-2">
                    <div className="card-head text-center mt-3 px-2">
                      <img src={gre} alt="" style={{ width: "150px" }} />
                      <hr />
                      <h5 className="text-primary">GRE</h5>
                    </div>

                    <p className="custom-p2 my-3">
                      The Graduate Record Examination, or GRE, is an important
                      step in the graduate school or business school application
                      process. The GRE is a multiple-choice, computer-based,
                      standardized exam that is often required for admission to
                      graduate programs and graduate business programs (MBA)
                      globally.
                    </p>

                    <div className="cardfooter text-center mb-3">
                      <button className="btn btn-sm btn-success">
                        <Link className="text-light" to="/gre">
                          Read More
                        </Link>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 country-card">
                  <div className="countrycardinner p-2">
                    <div className="card-head text-center mt-3 px-2">
                      <img
                        src={gmat}
                        className="rounded-3"
                        style={{ width: "120px" }}
                      />
                      <hr />
                      <h5 className="text-primary">GMAT</h5>
                    </div>

                    <p className="custom-p2 my-3">
                      GMAT is taken by more than 200,000 people annually and
                      only 6% of the total takers score 720 or more. What we can
                      understand from these data points is that GMAT is a hard
                      exam and it takes effort to score 700+
                    </p>

                    <div className="cardfooter text-center mb-3">
                      <button className="btn btn-sm btn-success">
                        <Link className="text-light" to="/gmat">
                          Read More
                        </Link>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 country-card">
                  <div className="countrycardinner p-2">
                    <div className="card-head text-center mt-3 px-2">
                      <img
                        src={toefl}
                        className="rounded-3"
                        style={{ width: "120px" }}
                      />
                      <hr />
                      <h5 className="text-primary">TOEFL</h5>
                    </div>

                    <p className="custom-p2 my-3">
                      The TOEFL iBT test helps you stand out confidently in
                      English. It’s the only test that measures all four
                      academic English skills — reading, listening, speaking and
                      writing — the way they are actually used in a classroom,
                      so you can be confident you’ll stand out to universities
                      where it counts.
                    </p>

                    <div className="cardfooter text-center mb-3">
                      <button className="btn btn-sm btn-success">
                        <Link className="text-light" to="/toefl">
                          Read More
                        </Link>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 country-card">
                  <div className="countrycardinner p-2">
                    <div className="card-head text-center mt-3 px-2">
                      <img
                        src={sat}
                        className="rounded-3"
                        style={{ width: "120px" }}
                      />
                      <hr />
                      <h5 className="text-primary">SAT</h5>
                      <h6>What’s a Good SAT Score for Colleges?</h6>
                    </div>

                    <p className="custom-p2 my-3">
                      An average SAT score is around 1000. Anything above 1200
                      is considered a good SAT score. The maximum anyone can
                      score on the SAT is the perfect score of 1600. Since you
                      receive scaled scores, you should have some understanding
                      of SAT scaled scores and percentiles.
                    </p>

                    <div className="cardfooter text-center mb-3">
                      <button className="btn btn-sm btn-success">
                        <Link className="text-light" to="/sat">
                          Read More
                        </Link>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="tab-pane fade" id="menu3">
            <div className="container-fluid pt-3">
              <div className="row">
                <div className="col-md-3 country-card">
                  <div className="countrycardinner p-2">
                    <div className="card-head text-center mt-3">
                      <img src={sop} alt="" style={{ width: "220px" }} />
                      <hr />
                      <h5 className="text-primary">Statement of Purpose</h5>
                      <h6>Process of SOP</h6>
                    </div>

                    <ul>
                      <li className="custom-p2">
                        Day1– SOP Questionnaire send to Student.
                      </li>
                      <li className="custom-p2">
                        Day4- Received from the student and checked by counselor
                      </li>
                      <li className="custom-p2">
                        Day5- SOP Checked by counselor and sent to editor.
                      </li>
                      <li className="custom-p2">
                        Day7-SOP Checked by editor and sent to Branch Manager
                        for review.
                      </li>
                      <li className="custom-p2">
                        Day8- SOP review done by Department Head and sent to
                        student for review.
                      </li>
                      <li className="custom-p2">
                        Day9- SOP review done by student and send to editor for
                        changes.
                      </li>
                      <li className="custom-p2">
                        Day10- SOP review done by Department Head and sent to
                        student for Second review.
                      </li>
                      <li className="custom-p2">Day11- SOP Finalisedntr</li>
                    </ul>

                    <div className="cardfooter text-center mb-3">
                      <button className="btn btn-sm btn-success">
                        <Link className="text-light" to="/sop">
                          Read More
                        </Link>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 country-card">
                  <div className="countrycardinner p-2">
                    <div className="card-head text-center mt-3">
                      <img src={lor} alt="" style={{ width: "220px" }} />
                      <hr />
                      <h5 className="text-primary">Letter of Recommendation</h5>
                      <h6>Writing Advice</h6>
                    </div>

                    <ul>
                      <li className="custom-p2">
                        Observe the university’s LOR criteria.
                      </li>
                      <li className="custom-p2">
                        All of the assertions in your Statement of Purpose
                        should be supported by the data in a LOR.
                      </li>
                      <li className="custom-p2">
                        Get letters of recommendation from persons connected to
                        various aspects of your academic and professional lives
                        if you require more than one LOR.
                      </li>
                      <li className="custom-p2">
                        All of the abilities and traits you state in the LOR
                        should be supported by concrete instances, for instance.
                        a task or activity that you excelled in.
                      </li>
                    </ul>

                    <div className="cardfooter text-center mb-3">
                      <button className="btn btn-sm btn-success">
                        <Link className="text-light" to="/lor">
                          Read More
                        </Link>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 country-card">
                  <div className="countrycardinner p-2">
                    <div className="card-head text-center mt-3">
                      <img src={resume} alt="" style={{ width: "220px" }} />
                      <hr />
                      <h5 className="text-primary">Resume</h5>
                      <h6>What to Include on a Resume?</h6>
                    </div>

                    <ul>
                      <li className="custom-p2">Full name</li>
                      <li className="custom-p2">
                        Your Job Title or the name of the position you’re
                        applying for
                      </li>
                      <li className="custom-p2">Contact information</li>
                      <li className="custom-p2">Resume summary or objective</li>
                      <li className="custom-p2">Work experience</li>
                      <li className="custom-p2">Education</li>
                      <li className="custom-p2">Relevant skills</li>
                      <li className="custom-p2">Languages and proficiency</li>
                      <li className="custom-p2">
                        Relevant certifications and interests (if any)
                      </li>
                    </ul>

                    <div className="cardfooter text-center mb-3">
                      <button className="btn btn-sm btn-success">
                        <Link className="text-light" to="/resume">
                          Read More
                        </Link>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
