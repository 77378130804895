import React from "react";
import Accordion from "react-bootstrap/Accordion";
import Table from "react-bootstrap/Table";
import denMap from "../assets/img/denmarkMap.jpeg";

type Props = {};

const DenComponent = (props: Props) => {
  return (
    <>
      <div>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>About Denmark</Accordion.Header>
            <Accordion.Body>
              <div className="d-md-flex flex-sm-column flex-md-row gap-2">
                <div className="col-md-6 mb-2">
                  <img
                    src={denMap}
                    className="mt-2 mx-4 img-fluid object-cover"
                    style={{ width: "95%" }}
                  />
                </div>
                <div className="col-md-6">
                  <Table striped bordered hover size="sm">
                    <tbody>
                      <tr>
                        <td>Location</td>
                        <td> Denmark is a Schengen country in Europe. </td>
                      </tr>
                      <tr>
                        <td>Capital</td>
                        <td>Copenhagen</td>
                      </tr>
                      <tr>
                        <td>Currency</td>
                        <td>Danish Krone (1 DKK = Rs 12.12 approx.) </td>
                      </tr>
                      <tr>
                        <td>Neighbouring Countries</td>
                        <td>Sweden, Norway, Germany, UK.</td>
                      </tr>
                      <tr>
                        <td>Languages Spoken</td>
                        <td>
                          English & Danish (No Language Barrier as 90% people
                          speak English)
                        </td>
                      </tr>
                      <tr>
                        <td>Largest Cities in Denmark</td>
                        <td>
                          Copenhagen, Aarhus, Odense, Aalborg, Esbjerg, Randers,
                          Kolding, Horsens, Vejle, Roskilde.
                        </td>
                      </tr>
                      <tr>
                        <td>Cost of Living</td>
                        <td>
                          5000 – 7000 DKK/ Month (living expenses vary person to
                          person)
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1">
            <Accordion.Header>Why Study in Denmark - USP</Accordion.Header>
            <Accordion.Body>
              <div>
                <Table striped bordered hover size="sm">
                  <tbody>
                    <tr>
                      <td>Part Time</td>
                      <td>
                        20 Hours/ Week and Full-Time during Summer months (June,
                        July, Aug)
                      </td>
                    </tr>
                    <tr>
                      <td>Post Study Visa</td>
                      <td>
                        <strong>Franchise Degree*</strong> – 6 Months;{" "}
                        <strong>Public University</strong> - 2 Years
                      </td>
                    </tr>
                    <tr>
                      <td>Intakes</td>
                      <td>September (Major) & February (Small)</td>
                    </tr>
                    <tr>
                      <td>Show Money</td>
                      <td>
                        <strong>No Funds</strong> Required to be shown in Visa
                        application for student.
                      </td>
                    </tr>
                    <tr>
                      <td>Wages</td>
                      <td>
                        <strong>DKK 150</strong>, depending on the type of job
                        and the student's skills and experience Earning e.g.
                        Minimum Wage (DKK 150/hour) X 20 hours/week = 3000 DKK x
                        4 weeks = DKK 12,000 per month, 12,000 DKK X 12 Months =
                        1,44,000 DKK / Year (i.e.{" "}
                        <strong>INR 17.50 Lacs approx.</strong>)
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Accordion.Body>
          </Accordion.Item>

          

          <Accordion.Item eventKey="2">
            <Accordion.Header>Popular Universities</Accordion.Header>
            <Accordion.Body>
              <div>
                <ul className="list-unstyled">
                  <li>
                    International Business Academy - IBA
                    <strong>(Indirect – Franchise Degree)</strong>
                  </li>
                  <li>
                    Niels Brock Copenhagen Business College
                    <strong>(Premium – Franchise Degree)</strong>
                  </li>
                  <li>
                    Technical University of Denmark
                    <strong>(Premium)</strong>
                  </li>
                  <li>
                    Aalborg University
                    <strong>(Premium)</strong>
                  </li>
                  <li>
                    University of Southern Denmark
                    <strong>(Premium)</strong>
                  </li>
                  <li>
                    Copenhagen Business School
                    <strong>(Premium)</strong>
                  </li>
                  <li>
                    Copenhagen University{" "}
                    <strong>(Premium) and many more</strong>
                  </li>
                </ul>
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="3">
            <Accordion.Header>Popular Programs</Accordion.Header>
            <Accordion.Body>
              <div>
                <Table striped bordered hover size="sm">
                  <tbody>
                    <tr>
                      <td>Bachelor</td>
                      <td>
                        BBA, International Business, Computer Science, SoŌware
                        Engineering, Mechanical Engineering.
                      </td>
                    </tr>
                    <tr>
                      <td>Master</td>
                      <td>
                        Management, MBA, Computer Engineering, Mechanical
                        Engineering, Software Engineering, Civil Engineering,
                        Architecture.
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="4">
            <Accordion.Header>Admission</Accordion.Header>
            <Accordion.Body>
              <div>
                <Table striped bordered hover size="sm">
                  <tbody>
                    <tr>
                      <td>Programs Available</td>
                      <td>Bachelor, Bachelor Top Up and Master</td>
                    </tr>
                    <tr>
                      <td>Duration</td>
                      <td>
                        Bachelor (3- 3.5 Years); Bachelor Top Up (1 Year);
                        Master (1-2 Years)
                      </td>
                    </tr>
                    <tr>
                      <td>Tuition Fee</td>
                      <td>DKK 80,000 to 120,000/ Year Approx.</td>
                    </tr>
                    <tr>
                      <td>Eligibility Criteria</td>
                      <td>
                        <li>
                          <strong>Bachelor’s –</strong> Class 12th with good
                          marks i.e. 60 and above (refer University specific
                          criteria)
                        </li>
                        <li>
                          <strong>Master’s –</strong> 3 to 4 Year Bachelor in
                          Related field with 55 and above.
                        </li>
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <span>
                  <strong className="text-decoration-underline">
                    Note that Public University Admission is Merit based, hence,
                    you must have 75% and higher.
                  </strong>
                </span>
                <p>
                  <strong>Language Proficiency Required: </strong>IELTS/ PTE/
                  TOEFL (Bachelor 6.0; Master - 6.5)
                </p>
                <p>
                  <strong>Documents Required for Admission Application:</strong>
                  <li>Application Fee – 100 Euro Approx.</li>
                  <li>Academic Marksheets and Certificates</li>
                  <li>CV, Motivation Letter, Letters of Recommendation and</li>
                  <li>Language Proficiency</li>
                </p>
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="5">
            <Accordion.Header>Application Processing Time</Accordion.Header>
            <Accordion.Body>
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>Intake</th>
                    <th>Application Period</th>
                    <th>Processing Time</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>September</td>
                    <td>Oct to 15th Jan</td>
                    <td>
                      Applications are evaluated post application deadline by
                      the University; hence it takes 2-3 months’ time. However,
                      private University deadline differs & take 2-6 weeks
                      approx. for application processing.
                    </td>
                  </tr>
                  <tr>
                    <td>February</td>
                    <td>July to 1st September</td>
                    <td>
                      Applications are evaluated post application deadline by
                      the University; hence it takes 2-3 months’ time. However,
                      private University deadline differs & take 2-6 weeks
                      approx. for application processing.
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="6">
            <Accordion.Header>Student Visa Requirements </Accordion.Header>
            <Accordion.Body>
              <Table striped bordered hover size="sm">
                <tbody>
                  <tr>
                    <td>Show Money</td>
                    <td>Not Applicable</td>
                  </tr>
                  <tr>
                    <td>Case Order ID Fee</td>
                    <td>2280 DKK To Danish Immigration (subject to change)</td>
                  </tr>
                  <tr>
                    <td>Embassy Fee</td>
                    <td>1700 DKK To Danish Embassy (subject to change)</td>
                  </tr>
                  <tr>
                    <td>VFS Appointment</td>
                    <td>Rs 2,000 approx.</td>
                  </tr>
                  <tr>
                    <td>Processing Time</td>
                    <td>2-3 Months aŌer Application Submitted.</td>
                  </tr>
                </tbody>
              </Table>
              <span>
                <strong>Note:</strong> Highest Degree Apostle (WNR) Required for
                the Visa Application.
              </span>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="7">
            <Accordion.Header>Important Notes</Accordion.Header>
            <Accordion.Body>
              <div>
                <ul>
                  <li>
                    <strong>Surname/ Family Name on Passport -</strong> It is
                    recommended that students have their surname on the passport
                    to get the TRP Card. Students get student card which helps
                    them to save money on travel expenses.
                  </li>
                  <li>
                    <strong>Establishment Card -</strong>Establishment card
                    would be given to those students who will be studying Danish
                    Degree from a Danish Public University. This card helps
                    students to open a business post completion of their
                    studies. 70% of Indian students for go for Non-Danish Degree
                    as they are not eligible for public Universities.
                  </li>
                  <li>
                    <strong>Franchise Degree – </strong>In the context of
                    Denmark, a "franchise degree" typically refers to a
                    collaborative arrangement between a Danish educational
                    institution and a foreign university or educational
                    provider. Upon successful completion of the program,
                    students receive a degree from the foreign institution with
                    which the franchise agreement is established. This degree is
                    recognized internationally according to the accrediting
                    institution’s guidelines
                  </li>
                </ul>
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="8">
            <Accordion.Header>
              After Visa and Arrival in Denmark
            </Accordion.Header>
            <Accordion.Body>
              <div>
                <div className="py-2">
                  <span>
                    After arrival in Denmark,{" "}
                    <strong>
                      tudent needs to apply for Student Residence permit card
                      copy and CPR card
                    </strong>
                    . Total processing time taken is 45-days approx.
                  </span>
                  <ul>
                    <li>
                      <strong>Register Your Address -</strong> - Within 5 days
                      of arrival, one must register their address at the local
                      citizen service center (Borgerservice) or the
                      International Citizen Service (ICS).
                    </li>
                    <li>
                      <strong>Obtain a CPR Number -</strong> Once student has
                      registered their address, they can apply for a CPR number
                      (Central Person Register number). This number is essential
                      for accessing various services in Denmark, including
                      healthcare, banking, and employment
                    </li>
                    <li>
                      <strong>Open a Bank Account -</strong> With CPR number,
                      students can open a bank account in Denmark. This is
                      necessary for managing finances, receiving wages when they
                      do part time work, and conducting financial transactions.
                    </li>
                    <li>
                      <strong>Apply for Residence Permit -</strong> If you are
                      planning to stay in Denmark for more than 3 months, you
                      need to apply for a Temporary Residence Permit asap.
                    </li>
                  </ul>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="9">
            <Accordion.Header>
              Benefits for Dependents (Family)
            </Accordion.Header>
            <Accordion.Body>
              <div>
                <div className="gap-y-2">
                  <h6>Family Reunification</h6>
                  <ul>
                    <li>
                      <strong>Residence Permits for Family Members:</strong>{" "}
                      Spouses and children of international students can apply
                      for residence permits to stay in Denmark during the
                      student's study period.
                    </li>
                    <li>
                      <strong>Work Rights for Spouses:</strong> Spouses of
                      international students are allowed to work full-time in
                      Denmark, providing additional financial support for the
                      family.
                    </li>
                  </ul>

                  <h6>
                    Public Education for Children of international Students:
                  </h6>

                  <ul>
                    <li>
                      <strong>Free Education:</strong> Denmark provides free
                      education in public schools for all children, including
                      those of international students. This covers primary and
                      lower secondary education (ages 6 to 16).
                    </li>
                    <li>
                      <strong>Language Support:</strong> Public schools oŌen
                      provide language support to help non-Danish- speaking
                      children integrate and succeed academically.
                    </li>
                    <li>
                      <strong> Integration Programs:</strong> There are
                      integration programs and activities designed to help
                      children adapt to the Danish education system and culture.
                    </li>
                  </ul>

                  <h6>Universal Healthcare Benefits:</h6>
                  <ul>
                    <li>
                      <strong>Free Access -</strong> Denmark has a universal
                      healthcare system, which means that all residents,
                      including international students and their families, have
                      access to free healthcare services.
                    </li>
                    <li>
                      <strong>National Health Insurance -</strong> Upon
                      registration in Denmark, you will receive a health
                      insurance card (sundhedskort), which grants access to a
                      wide range of healthcare services.
                    </li>
                  </ul>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="10">
            <Accordion.Header>Visa Requirements - Dependent</Accordion.Header>
            <Accordion.Body>
              <Table striped bordered hover size="sm">
                <tbody>
                  <tr>
                    <td>Show Money</td>
                    <td>
                      Rs 10 Lacs (Per Dependent) in student a/c, Fresh Money
                      Accepted. No ITRs needed.
                    </td>
                  </tr>
                  <tr>
                    <td>Case Order ID Fee</td>
                    <td>
                      2800 DKK (To Danish Immigration) - subject to change
                    </td>
                  </tr>
                  <tr>
                    <td>Embassy Fee</td>
                    <td>1700 DKK (To Danish Embassy) - subject to change</td>
                  </tr>
                  <tr>
                    <td>VFS Appointment</td>
                    <td>Rs 2000 approx.</td>
                  </tr>
                  <tr>
                    <td>Processing Time</td>
                    <td>2-3 Months after Application Submitted.</td>
                  </tr>
                </tbody>
              </Table>
              <div>
                <span>
                  <strong>Note:</strong> Marriage Certificate and Children Birth
                  Certificate Required with Apostle (WNR) for the Visa
                  Application.
                </span>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="11">
            <Accordion.Header>
              How to get Permanent Residency based on work
            </Accordion.Header>
            <Accordion.Body>
              <h4>
                To obtain a Danish Residence Permit based on WORK an individual
                needs to meet the certain criteria.
              </h4>

              <div>
                <ol>
                  <li>
                    Having a valid Job Offer from a Danish Employer processing
                    the necessary qualificaƟon and skills for the job and meeƟng
                    the criteria related for the work permit.
                  </li>
                  <li>
                    The DuraƟon of the Danish RP based Work can vary depending
                    upon the circumstance. In some cases, it can be issued for
                    the duraƟon of the employment contract whereas in other
                    cases it can be issued for a specific period of Ɵme.
                  </li>
                  <li>
                    Having a work permit allows one to legally reside and work
                    in Denmark. A change of job or role may require to obtain
                    new approval.
                  </li>
                </ol>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="12">
            <Accordion.Header>How to get Permanent Residency</Accordion.Header>
            <Accordion.Body>
              <h4>In order to apply for PR </h4>

              <div>
                <ul>
                  <li>
                    One must have 3.5 years’ full-Ɵme employment and paid taxes.
                  </li>
                  <li>Demonstrate Danish language through examinaƟon.</li>
                </ul>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </>
  );
};

export default DenComponent;
