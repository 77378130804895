import React from "react";
import Accordion from "react-bootstrap/Accordion";
import Table from "react-bootstrap/Table";
import finmap from "../assets/img/finmap.jpeg";

type Props = {};

const FinComponent = (props: Props) => {
  return (
    <>
      <div>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>About Finland </Accordion.Header>
            <Accordion.Body>
              <div className="d-md-flex flex-sm-column flex-md-row gap-2">
                <div className="col-md-6 mb-2">
                  <img
                    src={finmap}
                    className="mt-2 mx-4 img-fluid object-cover"
                    style={{ width: "95%" }}
                  />
                </div>
                <div className="col-md-6">
                  <Table striped bordered hover size="sm">
                    <tbody>
                      <tr>
                        <td>Location</td>
                        <td>Finland is a Schengen country in Europe. </td>
                      </tr>
                      <tr>
                        <td>Capital</td>
                        <td>Helsinki</td>
                      </tr>
                      <tr>
                        <td>Currency</td>
                        <td>Euro (1 Euro = Rs 90 approx.)</td>
                      </tr>
                      <tr>
                        <td>Neighbouring Countries</td>
                        <td>Sweden, Norway, Estonia.</td>
                      </tr>
                      <tr>
                        <td>Languages Spoken</td>
                        <td>
                          English & Finnish (No Language Barrier as 90% people
                          speak English)
                        </td>
                      </tr>
                      <tr>
                        <td>Cost of Living</td>
                        <td>
                          {" "}
                          5000 – 7000 DKK/ Month (living expenses vary person to
                          person)
                        </td>
                      </tr>
                      <tr>
                        <td>Average Travel Time to Finland from India</td>
                        <td>9 Hours and 30 Minutes</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Why Study in Finland - USP</Accordion.Header>
            <Accordion.Body>
              <div>
                <Table striped bordered hover size="sm">
                  <tbody>
                    <tr>
                      <td>Intakes</td>
                      <td>September (Major) & January (Small)</td>
                    </tr>
                    <tr>
                      <td>Part Time</td>
                      <td>
                        30 Hours/ Week and Full-Time during Summer months (June,
                        July, Aug){" "}
                      </td>
                    </tr>
                    <tr>
                      <td>Post Study Visa</td>
                      <td>
                        <ul>
                          <li>Bachelor and Master – 2 Years</li>
                          <li>Tailor Made Courses - NA</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>Show Money</td>
                      <td>
                        Rs 7-8 Lacs Required to be shown in student’s Savings
                        Account.
                      </td>
                    </tr>
                    <tr>
                      <td>Cost of Living</td>
                      <td>300-600 Euro/ Month</td>
                    </tr>
                    <tr>
                      <td>Wages</td>
                      <td>
                        10-13 Euro, e.g. Minimum Wage (10 Euro/hour) X 30
                        hours/week = 300 Euro x 4 weeks = 1,200 per month; 1,200
                        Euro X 12 Months = 14,400 Euro/ Year (i.e. INR 13 Lacs
                        approx. minimum)
                      </td>
                    </tr>
                    <tr>
                      <td>Gap Accepted</td>
                      <td>Yes</td>
                    </tr>
                    <tr>
                      <td>Dependent Visa Availability</td>
                      <td>
                        {" "}
                        Spouse can work Full Time & Free education for Kids.
                      </td>
                    </tr>
                    <tr>
                      <td>PR Possibility</td>
                      <td>
                        Can apply for PR after 4 years of stay on a “A Permit
                        Visa”; 100% Study Period is counted*.
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <div>
                  <ul>
                    <li>One of the Best Universities in the World.</li>
                    <li>
                      Number 1 Happiest Country in the World (6th Year in a
                      row).
                    </li>
                    <li>Number 1 Greenest Country in the World</li>
                    <li>One of the Safest Countries in the World.</li>
                  </ul>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              Intakes and Types of Applications
            </Accordion.Header>
            <Accordion.Body>
              <div>
                <h5>
                  September is a Major intake; January opens only for a very few
                  programs.
                </h5>
                <ul>
                  <li>Joint Application Period – Online Test Based </li>
                  <li>Rolling Application Period – Eligibility Based</li>
                  <li>Tailor Made Application Period </li>
                </ul>
                <h5>About Joint Application Period</h5>
                <ul>
                  <li>
                    It opens for a short span of time i.e. 15 days approx. only.
                  </li>
                  <li>All Degree Programs are available during this period.</li>
                  <li>
                    Student can apply more than 1 programs at a university.
                  </li>
                  <li>
                    This Application period is regulated by the Finnish Ministry
                    of Education and Online Entrance Exams are conducted (they
                    are very strict than normal)
                  </li>
                  <li>
                    Entrance Exam Pattern – 2 Phase (Phase 1 – Online Written;
                    Phase 2 – Group Discussion/ Interview)
                  </li>
                </ul>
                <h5>About Rolling Application Period</h5>
                <ul>
                  <li>
                    The duration of this application period is longer than Joint
                    Period.
                  </li>
                  <li>
                    Admission Criteria is fixed and the student meeting that
                    specific criterion only is admitted.
                  </li>
                  <li>
                    One student can apply only one program at one University.
                  </li>
                </ul>
                <span>
                  The university may or may not invite students for the entrance
                  or group discussions but it would be a little flexible as
                  compared to that of Joint Period.
                </span>

                <h5>About Tailor-Made Application Period</h5>
                <ul>
                  <li>
                    As the name suggests, these programs are tailor-made to meet
                    the needs of specific students who may not be eligible for
                    usual study programs.
                  </li>
                  <li>
                    This is commissioned to a specific agent and only they can
                    recruit students for the given program
                  </li>
                  <li>
                    Usually, the agent will ask for the tuition fee payment in
                    their account.
                  </li>
                  <li>
                    The admission process is more flexible and so are Online
                    Exam/ Interview
                  </li>
                  <li>
                    The students opting for these programs may fall under Visa
                    Permit B and hence they may not be entitled to stay back
                    visa of 2 years. Such students are recommended to enrol in a
                    Bachelor of Master program post completion of Tailor-made
                    study program.
                  </li>
                </ul>
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="3">
            <Accordion.Header>Popular Universities</Accordion.Header>
            <Accordion.Body>
              <div>
                <ul className="list-unstyled">
                  <li>
                    Karelia University of Applied Sciences{" "}
                    <strong>(Partner)</strong>
                  </li>
                  <li>
                    Satakunta University of Applied Sciences{" "}
                    <strong>(Non-Partner)</strong>
                  </li>
                  <li>
                    Hame University of Applied Sciences{" "}
                    <strong>(Non-Partner)</strong>
                  </li>
                  <li>
                    South-Eastern Finland University of Applied Sciences
                    <strong>(Non-Partner)</strong>
                  </li>
                  <li>
                    LUT University <strong>(Non-Partner)</strong>
                  </li>
                  <li>
                    Haaga-Helia University of Applied Sciences{" "}
                    <strong>(Non-Partner)</strong>
                  </li>
                  <li>
                    LAB University of Applied Sciences{" "}
                    <strong>(Non-Partner)</strong>
                  </li>
                  <li>
                    Aalto University <strong>(Non-Partner)</strong> And Many
                    More
                  </li>
                </ul>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>Popular Programs</Accordion.Header>
            <Accordion.Body>
              <div>
                <span>
                  International Business, Computer Science/ Engineering,
                  Tourism, Mechanical Engineering, MBA, Civil Engineering,
                  Architecture etc.
                </span>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>Admission</Accordion.Header>
            <Accordion.Body>
              <div>
                <Table striped bordered hover size="sm">
                  <tbody>
                    <tr>
                      <td>Programs Available</td>
                      <td>Bachelor, Master and Tailor-Made Diploma.</td>
                    </tr>
                    <tr>
                      <td>Duration</td>
                      <td>
                        <ul className="list-unstyled">
                          <li>Bachelor - 3.5 to 4 Years</li>
                          <li>Master - 1-2 Years</li>
                          <li>Tailor Made – 1 Year</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>Tuition Fee</td>
                      <td>9,000 to 11,000 Euro/ Year Approx.</td>
                    </tr>
                    <tr>
                      <td>Eligibility Criteria</td>
                      <td>
                        <ul>
                          <li>
                            Bachelor’s – Class 12th with good marks (refer
                            University specific criteria)
                          </li>
                          <li>
                            Master’s – 3 to 4 Year Bachelor in Related field
                            with 2+ years working experience (refer University
                            specific criteria)
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>Language Proficiency Required</td>
                      <td>
                        {" "}
                        IELTS (6.0 - 6.5)/ PTE/ TOEFL/ MOI{" "}
                        <strong>refer University specific criteria)</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Documents Required for Application</td>
                      <td>
                        <ul>
                          <li>Passport Copy</li>
                          <li>Academic Marksheets and Certificates</li>
                          <li>Language Proficiency</li>
                          <li>Experience Letter (if applicable)</li>
                          <li>CV (if applicable)</li>
                          <li>Motivation Letter (if applicable)</li>
                          <li>Letters of Recommendation (if applicable)</li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <span>
                  <strong>Note: </strong>that Public University Admission is
                  Merit based, higher the grades and exposure, better the
                  chances
                </span>
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="6">
            <Accordion.Header>Application Processing Time</Accordion.Header>
            <Accordion.Body>
              <div>
                <Table striped bordered hover size="sm">
                  <tbody>
                    <tr>
                      <td>Joint Period</td>
                      <td>
                        Applications are evaluated post application deadline by
                        the University; hence it takes 2 months’ approx.
                      </td>
                    </tr>
                    <tr>
                      <td>Rolling and Tailor-Made</td>
                      <td>
                        2-4 Weeks (Usually the selection is first come first
                        serve basis)
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="7">
            <Accordion.Header>Cost of Visa Application</Accordion.Header>
            <Accordion.Body>
              <div>
                <Table striped bordered hover size="sm">
                  <tbody>
                    <tr>
                      <td>Show Money</td>
                      <td>
                        Rs 7 Lacs for one year of study in the Student Account
                        only (3 Months Statement)
                      </td>
                    </tr>
                    <tr>
                      <td>Residence Permit Online Application Fee</td>
                      <td>Rs 31,000 approx.</td>
                    </tr>
                    <tr>
                      <td>VFS Charges</td>
                      <td>Rs 6,000 approx.</td>
                    </tr>
                    <tr>
                      <td>Travel Health Insurance</td>
                      <td>
                        (one year validity and 40,000 Euro Coverage): Rs 15,000
                        approx.
                      </td>
                    </tr>
                    <tr>
                      <td>Air-Fair</td>
                      <td>Rs 80,000 approx. </td>
                    </tr>
                    <tr>
                      <td>Processing Time</td>
                      <td>2-3 Months after Application Submitted.</td>
                    </tr>
                  </tbody>
                </Table>
                <h5>Documents Requirement:</h5>
                <ul>
                  <li>
                    Original private Bank Account Statement in the name of the
                    Student showing a balance of more than 7,00,000 and
                    movements during the last three months duly stamped and
                    signed by the bank.
                  </li>
                  <li>
                    A Bank Certificate in the name of the Student duly stamped
                    and signed by the bank.{" "}
                  </li>
                  <li>
                    Indian income tax return acknowledgment for the last three
                    assessment years of the Student which should have a total
                    income of more than 5,00,000/-.
                  </li>
                  <li>
                    If the student has received the funds for instance from
                    parents or relatives (a sponsor), the student is required to
                    submit additional documents on the origin of the funds which
                    may include Bank Statements of the sponsor showing the
                    sponsor’s financial movements in the last six months, ITRs
                    for Last two-three years, Occupation proof such as salary
                    slips (if employed), business registration certificates (if
                    self-employed), or pension-related documents (if retired).
                  </li>
                  <li>
                    Freshly deposited funds are accepted, but proof (source of
                    fund transfer/ bank statement) is required. Though NO CASH
                    TRANSFER ACCEPTABLE as can’t prove the source of the same.{" "}
                  </li>
                  <li>
                    In the case of a Loan along with a loan sanction letter, the
                    loan amount also needs to be disbursed in the student’s
                    account.{" "}
                  </li>
                  <li>
                    Only in case the student is under the age of 18, the account
                    can be a joint bank account that the student share with
                    their guardian (a letter from the bank is required stating
                    that you have a right to access the bank account). Also, the
                    Orignal Birth Certificate with English Translation needs to
                    be apostilled.{" "}
                  </li>
                </ul>
                <span>
                  <strong className="text-decoration-underline">NOTE:</strong>{" "}
                  The authority will check for funds of the students at the time
                  of immigration clearance or at any time. It is recommended to
                  keep a sufficient amount in the account throughout the period
                  of stay
                </span>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="8">
            <Accordion.Header>
              Finland Student Resident Permit Checklist
            </Accordion.Header>
            <Accordion.Body>
              <div>
                <ul>
                  <li>
                    <strong>Residence Permit Processing Time:</strong> 60-90
                    DAYS FROM THE DATE OF APPLICATION SUBMITTED ONLINE
                  </li>
                  <li>
                    Visa Application Deadline for Intake –{" "}
                    <strong>3-4 months prior Class Start</strong>
                  </li>
                  <li>
                    <strong>IELTS or TOEFL</strong> - University Specific
                  </li>
                </ul>
                <span>
                  Visa Related Charges Paid Directly by the Student - Rs. 61,000
                  (Approx)* - (Break given below):
                </span>
                <Table striped bordered hover size="sm">
                  <thead>
                    <tr>
                      <th>S.No.</th>
                      <th>Charges Type</th>
                      <th>Amount</th>
                      <th>Paid To</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Residence Permit Online Application Fee</td>
                      <td>Rs 40,000 Approx.</td>
                      <td>Finnish Immigration</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>VFS Appointment Charges</td>
                      <td>Rs 5677 Approx</td>
                      <td>VFS Finland</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Insurance</td>
                      <td>Rs 15,000 approx.</td>
                      <td>Insurance Provider</td>
                    </tr>
                  </tbody>
                </Table>
                <span>
                  <strong className="text-decoration-underline">NOTE:</strong>{" "}
                  *Above fees are Subject to change without prior notice
                </span>
                <h4>Financial Documents for Student Visa:</h4>
                <span className="fw-bolder text-decoration-underline">
                  Show Money:
                </span>
                <ul>
                  <li>
                    7 Lacs for one year of study in the main applicant Account
                    only
                  </li>
                </ul>
                <span className="fw-bolder text-decoration-underline">
                  Documents Requirement:
                </span>
                <ul>
                  <li>
                    Original personal <strong>Bank Account Statement</strong> in
                    the name of the Student showing a balance of more than{" "}
                    <strong>Rs 7,00,000</strong> and movements during the last{" "}
                    <em>three months duly stamped and signed by the bank.</em>
                  </li>
                  <li>
                    A <strong>Bank Certificate</strong> in the name of the
                    Student duly stamped and signed by the bank.
                  </li>
                  <li>
                    <strong>Last 3 Years ITRs </strong>of the Student which
                    should have a total income of more than
                    <strong> Rs 5 Lacs.</strong>
                  </li>
                  <li>
                    If the student has received the funds for instance from
                    <strong>parents or relatives (a sponsor),</strong> the
                    student is required to submit additional documents on the
                    origin of the funds which may include{" "}
                    <strong>Bank Statements of the sponsor</strong> showing the
                    sponsor’s financial movements in the{" "}
                    <strong>
                      last six months, ITRs for Last two-three years,
                    </strong>{" "}
                    Occupation proof such as salary slips (if employed),
                    business registration certificates (if self- employed), or
                    pension-related documents (if retired).
                  </li>
                  <li>
                    Freshly deposited funds are accepted, but proof (source of
                    fund transfer/ bank statement) is required. Though{" "}
                    <strong>NO CASH TRANSFER ACCEPTABLE</strong> as can’t prove
                    the source of the same.
                  </li>
                  <li>
                    In the case of an <strong>Education Loan</strong>, the loan
                    amount must be <em>disbursed in the student’s account</em>{" "}
                    along with a loan sanction letter.
                  </li>
                  <li>
                    Only in case the student is{" "}
                    <strong>under the age of 18,</strong> the account can be a
                    <strong>joint bank account</strong> that the student share
                    with their guardian (a letter from the bank is required
                    stating that you have a right to access the bank account).
                    Also, the Orignal Birth Certificate with English Translation
                    needs to be apostilled.
                  </li>
                </ul>
                <span>
                  <strong className="text-decoration-underline">NOTE </strong>-
                  The authority will check for funds of the students at the time
                  of immigration clearance or at any time. It is recommended to
                  keep a sufficient amount in the account throughout the period
                  of stay.
                </span>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="9">
            <Accordion.Header>Finland Spouse Visa Checklist</Accordion.Header>
            <Accordion.Body>
              <div>
                <ul>
                  <li>
                    <strong>Residence Permit Processing Time:</strong> 60-90
                    DAYS FROM THE DATE OF APPLICATION SUBMITTED ONLINE
                  </li>
                  <li>
                    <strong> Show Money Rs 8 Lakh approx.</strong> in the
                    applicant account only.
                    <ol>
                      <li>Bank certificate in the name of Applicant only.</li>
                      <li>Bank Statement of last six months in the name of Applicant only.</li>
                    </ol>
                  </li>
                </ul>
                <span>
                  Visa Related Charges Paid Directly by Applicant - Rs. 55,000
                  (Approx)* (Break up mentioned below):
                </span>
                <Table striped bordered hover size="sm">
                  <thead>
                    <tr>
                      <th>S.No.</th>
                      <th>Charges Type</th>
                      <th>Amount</th>
                      <th>Paid To</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Residence Permit Online Application Fee</td>
                      <td>Rs 42 ,000 Approx.</td>
                      <td>Finnish Immigration</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>VFS Appointment Charges</td>
                      <td>Rs 5,677 Approx</td>
                      <td>VFS</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Marriage Certificate- Apostle</td>
                      <td>Rs 2,000 approx.</td>
                      <td>Apostle Department</td>
                    </tr>
                  </tbody>
                </Table>
                <span>
                  <strong className="text-decoration-underline">NOTE:</strong>{" "}
                  *Above fees are Subject to change without prior notice
                </span>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="10">
            <Accordion.Header>
              CHILD (For Children under 18) Visa Checklist
            </Accordion.Header>
            <Accordion.Body>
              <div>
                <ul>
                  <li>
                    <strong>Residence Permit Processing Time:</strong> 60-90
                    DAYS FROM THE DATE OF APPLICATION SUBMITTED ONLINE
                  </li>
                  <li>
                    <strong>Show Money Rs 5.75 Lacs approx.</strong> in
                    applicant (joint account with father / mother) account only.
                    <ol>
                      <li>
                        Bank certificate in the name of applicant (joint account
                        with father / mother) only.
                      </li>
                      <li>
                        Bank Statement of the last six months in the name of
                        applicant only.
                      </li>
                    </ol>
                  </li>
                </ul>
                <span>
                  Visa Related Charges Paid Directly By Student - Rs. 34000
                  (Approx)* (Break up mention below):
                </span>
                <Table striped bordered hover size="sm">
                  <thead>
                    <tr>
                      <th>S.No.</th>
                      <th>Charges Type</th>
                      <th>Amount</th>
                      <th>Paid To</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Residence Permit Online Application Fee</td>
                      <td>Rs 24 ,000 Approx.</td>
                      <td>Finnish Immigration</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>VFS Service Fee</td>
                      <td>Rs 5,677 in cash</td>
                      <td>VFS</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Birth Certificate in English- Apostle</td>
                      <td>Rs 3,000 approx.</td>
                      <td>Apostle Department</td>
                    </tr>
                  </tbody>
                </Table>
                <span>
                  <strong className="text-decoration-underline">NOTE:</strong>{" "}
                  *Above fees are Subject to change without prior notice
                </span>

                <h4>IMPORTANT NOTES:</h4>
                <ul>
                  <li>
                    If any of the documents are not in Finnish, Swedish, or
                    English, please have them translated by an authorized
                    translator, and the translations will be attached to the
                    residence permit application.
                  </li>
                  <li>
                    File submission will be online and there may be an interview
                    at Embassy's discretion at the time of file submission at
                    VFS.
                  </li>
                  <li>
                    The whole family can apply at the same time, it is highly
                    recommended that the Spouse and Child apply for a Resident
                    Permit after the main applicant reaches Finland and gets the
                    Personal ID.
                  </li>
                  <li>
                    Spouses have an unrestricted right to work but cannot start
                    working before they get a residence permit.
                  </li>
                </ul>
                <span className="fw-bolder">
                  Always ensure the use of an Online Checklist in student
                  counseling sessions. Keep in mind that the checklist is
                  routinely revised to match the current standards of the
                  Embassy of Finland.
                </span>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </>
  );
};

export default FinComponent;
