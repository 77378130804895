import React from "react";

import latbg from "../assets/img/latviabg.jpg";
import latflag from "../assets/flags/latvia.png";
import LatComponent from "./LatComponent";

export default function Latvia() {
  return (
    <>
      {/* hero section start */}
      <div id="#customcontainer" className="customcontainer mt-4 pt-3">
        <div
          className="bg-image d-flex justify-content-center mt-5 align-items-center"
          style={{
            backgroundImage: `url(${latbg})`,
            height: "500px",
            backgroundSize: "cover",
            backgroundPosition: "top",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            backgroundBlendMode: "overlay",
          }}
        >
          <div className="row landingformlayout w-100 mx-0 px-5 mt-5">
            <div className="col-md-8 ieltslandingcontent">
              <h1 className="bannerHeading text-light">
                Why Study In Latvia ?
              </h1>
              <ul className="bannerPoints text-dark">
                <li className="my-3 ullist text-light">
                  <i className="fa fa-check-circle"></i> One of Best Education
                  System in Europe
                </li>
                <li className="my-3 ullist text-light">
                  <i className="fa fa-check-circle"></i> Quality Education
                </li>
                <li className="my-3 ullist text-light">
                  <i className="fa fa-check-circle"></i> Rapid Growth of Economy{" "}
                </li>
                <li className="my-3 ullist text-light">
                  <i className="fa fa-check-circle"></i> Over 1,50,000
                  international Students
                </li>
                <li className="my-3 ullist text-light">
                  <i className="fa fa-check-circle"></i> 1 Year Master's Degree
                </li>
                <li className="my-3 ullist text-light">
                  <i className="fa fa-check-circle"></i> Internationally
                  Recognized Qualification
                </li>
              </ul>
              <img
                src={latflag}
                className="mt-2 mx-4"
                style={{ width: "80px" }}
              />
            </div>

            <div
              className="ieltslandingcontentmobile"
              style={{ display: "none" }}
            >
              <h1 className="bannerHeading text-light">
                Why to Study in Latvia ?
              </h1>
            </div>
          </div>
        </div>
      </div>
      {/* hero section ended */}

      <div className="container">
        <div className="section-head col-sm-12 mb-1 mt-3">
          <h4>
            <span>Latvia</span>
          </h4>
        </div>
        <LatComponent />
      </div>
    </>
  );
}
