import React from 'react'

export default function Couseuk() {
  return (
    <>
      <div className="accordion" id="accordionExample">
        
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingThree">
      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
      Popular courses
      </button>
    </h2>
    <div id="collapseThree" className="accordion-collapse collapse " aria-labelledby="headingThree" data-bs-parent="#accordionExample">
    <div className="accordion-body">
    <p>Sociology and Social Studies, Law, Business Management, Computer Science, Communications/Media, Life Sciences, Engineering</p>
    </div>
    </div>
  </div>

  <div className="accordion-item">
    <h2 className="accordion-header" id="headingFour">
      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
      Intakes
      </button>
    </h2>
    <div id="collapseFour" className="accordion-collapse collapse " aria-labelledby="headingFour" data-bs-parent="#accordionExample">
    <div className="accordion-body">
    <p>Main intake seasons are September/October and January/February.</p>
    <p>Some Universities offer few programs in May also.</p>
    <br />
    <p>Major intake for UG programs is September/October only. A handful of universities offer limited numbers of programs mainly in Business or IT for January intake.</p>
    <p>Bachelor degree programs are not offered for May intake.</p>

    </div>
    </div>
  </div>

 

  </div>
    </>
  )
}
