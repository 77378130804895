import React from 'react'
import Module1 from './Module1'
import Module2 from './Module2'
import Module3 from './Module3'
import Canadatraining from './Canadatraining'
import Canada5sec from './Canada5sec'
import Canada6sec from './Canada6sec'
import canadabg from '../assets/img/canadabg.jpg'
import canadaflag from '../assets/flags/Canada.png'


export default function Canadapage() {
  return (
    <>
      {/* hero section start */}
  <div id='#customcontainer' className="customcontainer mt-4 pt-3">
 <div className="bg-image d-flex justify-content-center mt-5 align-items-center" style={{
  backgroundImage: `url(${canadabg})`,
  height: '500px',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  backgroundBlendMode: 'overlay'
}}>
    <div className="row landingformlayout w-100 mx-0 px-5 mt-5">
     <div className="col-md-8 ieltslandingcontent">
     <h1 className="bannerHeading text-light display-6">Why to Study in Canada?</h1>
		<ul className="bannerPoints text-dark">
			<li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> Qualifications valued around the World.</li>
			<li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> Affordable Education. </li>
			<li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> Multicultural Society.</li>
			<li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> Healthy and Safe Communities.  </li>
			<li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> Possibility of paid Internships while studying. </li>
			<li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> Post Study Work Visa and good Job opportunities after completion of studies.</li>
			<li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> Possibility of Permanent Residency. </li>
		</ul>
            <img src={canadaflag} className='mt-2 mx-4' style={{width:'80px'}}/>
     </div>

     <div className="ieltslandingcontentmobile" style={{display:'none'}}>
     <h1 className="bannerHeading text-light">Why to Study in Canada?</h1>
</div>



    </div>
 
</div>

 </div>
{/* hero section ended */}

<div className="container">
    <div className="section-head col-sm-12 mb-1 mt-3">
        <h4><span>MODULE </span>1</h4>
    </div>

    <Module1/>

    <div className="section-head col-sm-12 mb-1 mt-3">
        <h4><span>MODULE </span>2</h4>
    </div>

    <Module2/>

    <div className="section-head col-sm-12 mb-1 mt-3">
        <h4><span>MODULE </span>3</h4>
    </div>

    <Module3/>

    <div className="section-head col-sm-12 mb-1 mt-3">
        <h4><span>Canada Training </span>Advanced Level</h4>
        <p>In this training session we will be discussing about the most common questions asked by the prospective students to us.</p>
    </div>

    <Canadatraining/>

    <div className="section-head col-sm-12 mb-1 mt-3">
        <h4><span>Questions Related To </span>Work Rights And Settlement</h4>
    </div>

    <Canada5sec/>

    <div className="section-head col-sm-12 mb-1 mt-3">
        <h4><span>Questions Related To </span>Changing DLI</h4>
    </div>

    <Canada6sec/>


</div>

    </>
  )
}
