import React from 'react'
import ausbg from '../../assets/imgnew/elitebg2.jpg'
import lorimg1 from '../../assets/newfolder/lorimg1.png'
import lorimg2 from '../../assets/newfolder/lorimg2.png'

export default function Lorpage() {
  return (
    <>
             {/* hero section start */}
  <div id='#customcontainer' className="customcontainer mt-4 pt-3">
 <div className="bg-image d-flex justify-content-center mt-5 align-items-center" style={{
  backgroundImage: `url(${ausbg})`,
  height: '500px',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  backgroundBlendMode: 'overlay'
}}>
    <div className="row landingformlayout w-100 mx-0 px-5 mt-5">
    <div className="col-md-8 ieltslandingcontent">
     <h1 className="bannerHeading text-light display-4">Crafting Your Path To Success</h1>
     <p className='text-light fs-5'><em>Unlock Academic Excellence With Powerful LORs.
Explore Inspiring Success Stories And Take Your Education To New Heights.</em></p>

        {/* <img src={sat} className='rounded-3' style={{width:'120px'}}/> */}
     </div>

     <div className="ieltslandingcontentmobile" style={{display:'none'}}>
     <h1 className="bannerHeading text-light">Boost Your GMAT Score With GlobEDwise</h1>
</div>



    </div>
 
</div>

 </div>
{/* hero section ended */}

<div className="container py-3">
<h4 className="mb-4">What is a LOR?</h4>
        <p className="mb-4">
            A letter written by a professor or an employer who collaborated with you for a significant period of time, in which they will recommend you for a particular study program.
        </p>
        
        <h4 className="mb-3">Writing Advice</h4>
        <ul className="mb-4">
            <li>Observe the university’s LOR criteria.</li>
            <li>All of the assertions in your Statement of Purpose should be supported by the data in a LOR.</li>
            <li>Get letters of recommendation from persons connected to various aspects of your academic and professional lives if you require more than one LOR.</li>
            <li>All of the abilities and traits you state in the LOR should be supported by concrete instances, for instance, a task or activity that you excelled in.</li>
            <li>Considering the requirements of the application, carefully select your recommender.</li>
        </ul>

        <p className="mb-4">Please follow the accepted LOR Format given below:</p>

        <div className="mb-4">
            <h5>Para 1: Introduction</h5>
            <p>
                This includes a brief summary of the recommender’s qualifications (name, title, college or company, and information about his connection with the client), how long and in what capacity the recommender has known the applicant, and, to emphasise the applicant’s position, a comparison of the applicant’s standing with any other students he has taught or supervised. He or she must list duties, including the number of persons under supervision. Finish this sentence by highlighting the client’s remarkable attributes that led the recommender to suggest the client.
            </p>
        </div>

        <div className="mb-4">
            <h5>Para 2</h5>
            <p>
                Discuss one Quality (for each paragraph, choose one that relates to the client, such as academic prowess, research aptitude, ability to think creatively, leadership, organisational skills, teamwork skills, taking initiative, work ethics, and/or maturity) and provide a convincing example of a time when the client exhibited that quality. This has major significance.
            </p>
        </div>

        <div className="mb-4">
            <h5>Para 3</h5>
            <p>
                Talk about any additional trait and give a strong example of when the applicant exhibited it.
            </p>
        </div>

        <div className="mb-4">
            <h5>Para 4</h5>
            <p>
                Discuss yet another Quality and provide an impressive example of when the applicant client displayed that quality.
            </p>
        </div>

        <div className="mb-4">
            <h5>Para 5</h5>
            <p>
            Summary of the candidate’s advantages and a resounding, passionate support of their candidacy
            </p>
        </div>
        <p><em>Important:- If there are 2-3 LORs for one applicant, each version should highlight a distinct quality so
that they do not appear to have been prepared by just one person, and the examples should solely
pertain to that recommender</em></p>

<table className="table table-bordered">
            <thead className="table-dark">
                <tr>
                    <th>Do's</th>
                    <th>Don'ts</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Provide a contextual and concrete comparison of the student’s performance.</td>
                    <td>Use of general language while writing.</td>
                </tr>
                <tr>
                    <td>Highlight personalized examples of the student’s work, such as a standout final paper, semester-long research project, etc.</td>
                    <td>Avoid backhanded compliments or faint praise.</td>
                </tr>
                <tr>
                    <td>Discuss the merits of the student’s proposed plan or course of research.</td>
                    <td>Do not exaggerate about basic expectations from a student.</td>
                </tr>
                <tr>
                    <td>Provide explanation of how the fellowship aligns with the student’s future aspirations.</td>
                    <td>Do not exceed more than one page.</td>
                </tr>
                <tr>
                    <td>Provide rich and convincing detail.</td>
                    <td>Avoid mentioning skills and talent that are not relevant to the course.</td>
                </tr>
            </tbody>
        </table>

        <h4>Candidates without a history of employment</h4>
        <p>This group often includes applicants for undergraduate programmes. However, some graduates choose to start their master’s programme right away. If you don’t have any job experience, you can contact the folks listed below for a LOR:</p>
        <ul>
            <li>Your teachers or professors</li>
            <li>HOD (Head of Department)</li>
            <li>Your school principal</li>
            <li>The dean of your college</li>
            <li>Director of your university</li>
        </ul>
        <p>Your master’s LOR’s credibility may eventually be raised if it is written by your academic mentor or teacher. A professor’s letter of recommendation for a master’s degree is simple to obtain.</p>

        <h4>Candidates with a history of employment</h4>
        <p>Candidates with job experience might request LOR from those with whom they have maintained strong professional ties.</p>
        <ul>
            <li>A person who knows you well</li>
            <li>Someone who has pursued advanced higher education and been your boss or supervisor in a job or internship</li>
            <li>A person who is your immediate manager.</li>
        </ul>

        <div className="section-head col-sm-12 mb-1 mt-3">
        <h4><span>Sample's</span></h4>
    </div>

    <h5>University/School Letter Head</h5>
    <img src={lorimg1} alt="" className="img-fluid mb-5" />

    <h5>Company's Letter Head</h5>
    <img src={lorimg2} alt="" className="img-fluid" />

</div>
    </>
  )
}
