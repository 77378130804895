import React from 'react'
import ausbg from '../../assets/imgnew/shortlistbg.jpg'
import { Accordion } from 'react-bootstrap'


export default function Resumepage() {
  return (
    <>
        {/* hero section start */}
  <div id='#customcontainer' className="customcontainer mt-4 pt-3">
 <div className="bg-image d-flex justify-content-center mt-5 align-items-center" style={{
  backgroundImage: `url(${ausbg})`,
  height: '500px',
  backgroundSize: 'cover',
  backgroundPosition: 'bottom',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  backgroundBlendMode: 'overlay'
}}>
    <div className="row landingformlayout w-100 mx-0 px-5 mt-5">
    <div className="col-md-8 ieltslandingcontent">
     <h1 className="bannerHeading text-light display-4">Crafting Your Path To Success</h1>
     <p className='text-light fs-5'><em>Unlock Academic Excellence With Powerful Resume.
Explore Inspiring Success Stories And Take Your Education To New Heights.</em></p>

        {/* <img src={sat} className='rounded-3' style={{width:'120px'}}/> */}
     </div>

     <div className="ieltslandingcontentmobile" style={{display:'none'}}>
     <h1 className="bannerHeading text-light">Boost Your GMAT Score With GlobEDwise</h1>
</div>



    </div>
 
</div>

 </div>
{/* hero section ended */}

<div className="container py-3">

{/* <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Accordion Item #1</Accordion.Header>
        <Accordion.Body>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Accordion Item #2</Accordion.Header>
        <Accordion.Body>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion> */}
<div>
        
        <h2 className='text-danger'>RESUME AND CURRICULUM VITAE (CV)</h2>
        <h4>Difference Between Resume and CV</h4>
        <table className="table table-bordered">
            <thead className='bg-dark text-light'>
                <tr>
                    <th>BASIS FOR COMPARISON</th>
                    <th>CURRICULUM VITAE (CV)</th>
                    <th>RESUME</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Meaning</td>
                    <td>A CV (Curriculum Vitae) is a longer document that details the whole course of your career</td>
                    <td>A resume is a one- to two-page document presenting key facts about your professional experience, educational background, and skills</td>
                </tr>
                <tr>
                    <td>Type of Document</td>
                    <td>Comprehensive</td>
                    <td>Concise</td>
                </tr>
                <tr>
                    <td>Purpose</td>
                    <td>A CV—for academic purposes</td>
                    <td>A resume is used for job search</td>
                </tr>
                <tr>
                    <td>Length</td>
                    <td>2 to 20 or more pages</td>
                    <td>1 to 2 pages</td>
                </tr>
                <tr>
                    <td>References</td>
                    <td>Included</td>
                    <td>Not Included</td>
                </tr>
                <tr>
                    <td>Information includes</td>
                    <td>A Curriculum Vitae contains details about your education, professional career, publications, awards, honors, and other achievements.</td>
                    <td>A brief overview of the candidate’s work history.</td>
                </tr>
            </tbody>
        </table>
       
    </div>
    <div>
      
        <h4>What to Include on a Resume</h4>
        <ul>
            <li>Full name</li>
            <li>Your Job Title or the name of the position you’re applying for</li>
            <li>Contact information</li>
            <li>Resume summary or objective</li>
            <li>Work experience</li>
            <li>Education</li>
            <li>Relevant skills</li>
            <li>Languages and proficiency</li>
            <li>Relevant certifications and interests (if any)</li>
        </ul>
        <h4>What to Include on a CV</h4>
        <ul>
            <li>Full name</li>
            <li>Contact information</li>
            <li>Professional title</li>
            <li>Research interests</li>
            <li>Education</li>
            <li>Publications (both academic papers and books)</li>
            <li>Teaching or lecturing experience</li>
            <li>Work experience</li>
            <li>Conferences and courses</li>
            <li>Skills</li>
            <li>Certificates</li>
            <li>Languages</li>
            <li>Grants of fellowships</li>
            <li>References</li>
        </ul>
        <h4>Do’s and Don’ts in Resume</h4>

        <table className="table table-bordered">
    <thead className='bg-dark text-light'>
        <tr>
            <th>DO’S</th>
            <th>DON’TS</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>Use Formatting to Highlight Important Information</td>
            <td>Don’t Highlight Irrelevant Information</td>
        </tr>
        <tr>
            <td>Include a Powerful Headline and Professional Summary</td>
            <td>Don’t Mention Anything Controversial</td>
        </tr>
        <tr>
            <td>Regularly Update the Skills Section</td>
            <td>Don’t Add Hobbies or Interests that Are Unrelated to the Job</td>
        </tr>
        <tr>
            <td>Show Your Career Progress and Advancement</td>
            <td>Don’t Lie About Employment Gaps</td>
        </tr>
        <tr>
            <td>Include Hard and Soft Skills</td>
            <td>Don’t Offer to Provide References</td>
        </tr>
    </tbody>
</table>

        <h4>Do’s and Don’ts in CV</h4>
      <table className="table table-bordered">
    <thead className='bg-dark text-light'>
        <tr>
            <th>DO’S</th>
            <th>DON’TS</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>Present things in a logical order.</td>
            <td>A CV should not include any fancy or flashy formatting in order to retain a formal and professional appearance.</td>
        </tr>
        <tr>
            <td>Proofread and proofread again.</td>
            <td>Don’t put personal information, such as your photo, height, marital status or religious affiliation.</td>
        </tr>
        <tr>
            <td>Use bullet points</td>
            <td>Don’t Highlight Irrelevant Information</td>
        </tr>
        <tr>
            <td>Use a clear, uncluttered layout, without too many special effects.</td>
            <td>Don’t write in paragraphs.</td>
        </tr>
    </tbody>
</table>

        <p>Copyright © 2024 | GlobEDwise</p>
        <p>Provide the titles of research projects and course names along with brief summaries of your work.</p>
        <p>Don’t lie about your qualifications.</p>
    </div>
</div>

    </>
  )
}
