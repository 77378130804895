import React from 'react'
import ausbg from '../../assets/imgnew/grebg.jpg'
import ausflag from '../../assets/img/grelogo2.png'

export default function Grepage() {
  return (
    <>
                     {/* hero section start */}
  <div id='#customcontainer' className="customcontainer mt-4 pt-3">
 <div className="bg-image d-flex justify-content-center mt-5 align-items-center" style={{
  backgroundImage: `url(${ausbg})`,
  height: '500px',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  backgroundBlendMode: 'overlay'
}}>
    <div className="row landingformlayout w-100 mx-0 px-5 mt-5">
    <div className="col-md-8 ieltslandingcontent">
     <h1 className="bannerHeading text-light">Master the GRE with GlobEDwise</h1>
		<ul className="bannerPoints text-dark">
			<li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> Certified Verbal and Quants Trainers</li>
			<li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> Delivering consistently the best Results from the last 10 years</li>
			<li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> Updated and well-researched material</li>
			<li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> Regular Mock Test & One-to-One Sessions</li>
			<li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> Access to 1000+ questions that match the GRE format</li>
			<li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> Use the GRE question Library to anticipate future test questions</li>
		</ul>
            <img src={ausflag} className='mt-2 mx-4 bg-light p-2' style={{width:'120px'}}/>
     </div>

     <div className="ieltslandingcontentmobile" style={{display:'none'}}>
     <h1 className="bannerHeading text-light">Master the GRE with GlobEDwise</h1>
</div>



    </div>
 
</div>

 </div>
{/* hero section ended */}


<div className="section-head col-sm-12 mb-1 mt-3">
        <h4><span>GRE</span></h4>
    </div>

    <div className="container">
    <h5>About The Test</h5>
    <p>The GRE General Test closely reflects the kind of thinking you’ll do in today’s demanding graduate
school programs, including business and law. It measures your verbal reasoning, quantitative
reasoning, critical thinking, and analytical writing skills—skills that have been developed over a long
period of time and aren’t related to a specific field of study but are important for all.</p>

<table className="table table-bordered">
            <thead className="table-dark">
                <tr>
                    <th>Section</th>
                    <th>Score Range</th>
                    <th>Abilities Measured</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Verbal Reasoning</td>
                    <td>130–170, in 1-point increments</td>
                    <td>
                        <ul>
                            <li>Analyze and draw conclusions from discourse; reason from incomplete data; identify author’s assumptions and/or perspective; understand multiple levels of meaning, such as literal, figurative and author’s intent</li>
                            <li>Select important points; distinguish major from minor or irrelevant points; summarize text; understand the structure of a text</li>
                            <li>Understand the meaning of individual words, sentences and entire texts; understand relationships among words and among concepts</li>
                        </ul> 
                        <a href="#">Take a closer look at the Verbal Reasoning section.</a>
                    </td>
                </tr>
                <tr>
                    <td>Quantitative Reasoning</td>
                    <td>130–170, in 1-point increments</td>
                    <td>
                        <ul>
                            <li>Understand, interpret and analyze quantitative information</li>
                            <li>Solve problems using mathematical models</li>
                            <li>Apply basic skills and elementary concepts of arithmetic, algebra, geometry and data analysis</li>
                        </ul>
                        <a href="#">Take a closer look at the Quantitative Reasoning section.</a>
                    </td>
                </tr>
                <tr>
                    <td>Analytical Writing</td>
                    <td>0–6, in half-point increments</td>
                    <td>
                        <ul>
                            <li>Articulate complex ideasclearly and effectively</li>
                            <li>support ideas with relevant reasons and examples</li>
                            <li>examine claims and accompanying evidence</li>
                            <li>sustain a well-focused, coherent discussion</li>
                            <li>control the elements ofstandard written English</li>
                        </ul>
                        <p>It requires you to provide focused responses based on the tasks presented, so you can accurately demonstrate your skill in directly responding to a task.</p>
                    </td>
                </tr>

                </tbody>
                </table>

                <h5>Schedule Your GRE General Test</h5>
                <p>You can take the GRE General Test once every 21 days, up to five times within any continuous rolling 12-months period (365 days). This applies even if you canceled your scores on a test taken previously</p>
                <p>When selecting a test date, make sure your scores will be reported in time for your application deadlines. Score reports are sent to your designated score recipients approximately 10–15 days after your test date. When choosing a test date, allow time for delivery of scores and processing by the institution.</p>

                <h5>Getting Your GRE General Test Scores</h5>
                <table className="table table-bordered">
            <thead className="table-dark">
                <tr>
                    <th>Section</th>
                    <th>Score Scale</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Verbal Reasoning</td>
                    <td>130–170, in 1-point increments</td>
                </tr>
                <tr>
                    <td>Quantitative Reasoning</td>
                    <td>130–170, in 1-point increments</td>
                </tr>
                <tr>
                    <td>Analytical Writing</td>
                    <td>0–6, in half-point increments</td>
                </tr>
            </tbody>
        </table>
        <p>If no questions are answered for a specific measure (e.g., Verbal Reasoning), then you will receive a No Score (NS) for that measure</p>

        <h5>When will I get my scores?</h5>
        <p>Your official GRE General Test scores will be available in your ETS account10–15 days after your test date. You’ll receive an email from ETS when they are available.</p>
        <p>ETS will also send an official Institution Score Report to the score recipients you designated on test day at that time</p>

        <h5>What’s in my official score report?</h5>
        <p>Accessible in your ETS account, your official GRE Test-taker Score Report is intended only for your information and personal records. It contains your:</p>
        <ul>
            <li>contact information (name, phone number and email)</li>
            <li>date of birth</li>
            <li>gender</li>
            <li>intended graduate major</li>
            <li>test date(s)</li>
            <li>GRE test score(s) and the associated percentile ranks</li>
            <li>authorized score recipients or fellowship sponsors and the scores reported to those institutions</li>
            <li>cumulative record of scores reported within the last 5 years</li>
        </ul>
        <p>If you wish to have a paper copy, you can print one from your ETS account. It won’t be sent to you by mail.</p>

        <h5>What information is sent to institutions?</h5>
        <h6>Official score reports sent to the institutions you designate include:</h6>
        <ul>
        <li>contact information (name, phone number and email)</li>
            <li>date of birth</li>
            <li>gender</li>
            <li>intended graduate major</li>
            <li>the GRE test dates and score(s) you choose to report with the ScoreSelect® option and the associated percentile ranks</li>
        </ul>
        <h6>They don’t include:</h6>
        <ul>
            <li>any information concerning the other score recipients you havechosen</li>
            <li>any scores you’ve chosen not to report</li>
            <li>any indication that you’ve taken other GRE tests</li>
        </ul>
        <p>Photos and essay responses from each GRE General Test administration you select from your 5-year reportable history will be available to your score recipients in the ETS® Data Manager as part of your score record</p>

</div>
    </>
  )
}
