import React from "react";
import Accordion from "react-bootstrap/Accordion";
import Table from "react-bootstrap/Table";
import swedenMap from "../assets/img/swedenMap.jpeg";
import { Link } from "react-router-dom";

type Props = {};

const SwedenComponent = (props: Props) => {
  return (
    <>
      <div>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>About Sweden</Accordion.Header>
            <Accordion.Body>
              <div className="d-md-flex flex-sm-column flex-md-row gap-2">
                <div className="col-md-6 mb-2">
                  <img
                    src={swedenMap}
                    className="mt-2 mx-4 img-fluid object-cover"
                    style={{ width: "95%" }}
                  />
                </div>
                <div className="col-md-6 d-flex align-items-center">
                  <Table striped bordered hover size="sm">
                    <tbody>
                      <tr>
                        <td>Location</td>
                        <td>Sweden is a Schengen country in Europe.</td>
                      </tr>
                      <tr>
                        <td>Capital</td>
                        <td>Stockholm</td>
                      </tr>
                      <tr>
                        <td>Currency</td>
                        <td>Swedish Krone i.e. SEK (1 SEK = Rs 9 approx.)</td>
                      </tr>
                      <tr>
                        <td>Neighbouring Countries</td>
                        <td>Finland, Denmark and Norway.</td>
                      </tr>
                      <tr>
                        <td>Languages Spoken</td>
                        <td>
                          English & Swedish (No Language Barrier as 90% people
                          speak English)
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1">
            <Accordion.Header>Why Study in Sweden?</Accordion.Header>
            <Accordion.Body>
              <Table striped bordered hover size="sm">
                <tbody>
                  <tr>
                    <td>Part Time</td>
                    <td>
                      International students can work{" "}
                      <strong>Unlimited Hours</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Post Study Visa</td>
                    <td>1 Year</td>
                  </tr>
                  <tr>
                    <td>Application Fee</td>
                    <td>900 SEK (Mandatory)</td>
                  </tr>
                  <tr>
                    <td>Show Money</td>
                    <td>
                      <strong>
                        SEK 10 314 per month (SEK 1,23,768) i.e. Rs 10 Lac
                        approx.
                      </strong>
                      required to be shown in student’s Savings Account (6
                      months statement, fresh money accepted)
                    </td>
                  </tr>
                  <tr>
                    <td>Cost of Living</td>
                    <td>
                      4000 – 6000 SEK/ Month (living expenses vary person to
                      person)
                    </td>
                  </tr>
                  <tr>
                    <td>Gap Accepted</td>
                    <td>If covered.</td>
                  </tr>
                  <tr>
                    <td>Dependent Visa Availability</td>
                    <td>
                      <strong>Spouse can work Full Time</strong> & Kids can also
                      accompany.
                    </td>
                  </tr>
                  <tr>
                    <td>PR Possibility</td>
                    <td>
                      Can apply for PR once they have lived and worked in Sweden
                      for 5 years.
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="2">
            <Accordion.Header>Important Application Dates</Accordion.Header>
            <Accordion.Body>
              <div>
                <Table striped bordered hover size="sm">
                  <tbody>
                    <tr>
                      <td>
                        <strong>Intakes</strong>
                      </td>
                      <td>
                        <strong>September</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Application Period and Deadline</td>
                      <td>
                        16th October to <strong>15th January </strong>(no
                        deadline extensions)
                      </td>
                    </tr>
                    <tr>
                      <td>Supporting Document Submission Deadline</td>
                      <td>
                        1<sup>st</sup> February
                      </td>
                    </tr>
                    <tr>
                      <td>Admission Result Published Date</td>
                      <td>
                        21<sup>st</sup> March (Master); 11<sup>th</sup> April
                        (Bachelor)
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <p>
                To receive your Residence permit in time to begin studies in
                Sweden, Ione must pay their tuition fee as well as submit the
                residence permit application asap after receiving the
                Notification of Selection Results i.e. admission.
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="3">
            <Accordion.Header>Universities represented</Accordion.Header>
            <Accordion.Body>
              <div>
                <ol className="">
                  <li>Linnaeus University</li>
                  <li>University of Skövde</li>
                  <li>Chalmers University of Technology</li>
                  <li>Lund University</li>
                  <li>KTH Royal Institute of Technology</li>
                  <li>Uppsala University</li>
                  <li>Jonkoping University</li>
                  <li>Dalarna University</li>
                  <li>Malmo University</li>
                  <li>Stockholm University</li>
                  <li>Halmstad University</li>
                  <li>
                    Kristianstad University <strong>& many More</strong>
                  </li>
                </ol>
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="4">
            <Accordion.Header>Admission</Accordion.Header>
            <Accordion.Body>
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>Programs Available</th>
                    <th>
                      <strong>Bachelor and Master.</strong>
                    </th>
                  </tr>
                </thead>
              </Table>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="5">
            <Accordion.Header>
              Counselling Support Sheet – Sweden
            </Accordion.Header>
            <Accordion.Body>
              <div>
                <Table striped bordered hover size="sm">
                  <tbody>
                    <tr>
                      <td>Duration of Studies</td>
                      <td>
                        <li>
                          <strong>Bachelor</strong> - 3 Years
                        </li>
                        <li>
                          <strong>Master</strong> - 1 to 2 Years (It is always
                          recommended that students go for 2 Year Master)
                        </li>
                      </td>
                    </tr>
                    <tr>
                      <td>Study Fields Available</td>
                      <td>
                        All study fields such as Business, Engineering,
                        Architecture, Social Sciences, Life Sciences, Art,
                        Design etc.
                      </td>
                    </tr>
                    <tr>
                      <td>Eligibility Criteria</td>
                      <td>
                        <li>
                          <strong>Bachelor’s</strong> – Class 12th/ 10+3 in
                          Engineering with good marks{" "}
                          <strong>(refer University specific criteria)</strong>
                        </li>
                        <li>
                          <strong>Master’s</strong> – 4 Years Bachelor in
                          Related field/ 3+2 Years Study from India can apply
                          for Master{" "}
                          <strong>(refer University specific criteria)</strong>
                        </li>
                        <li className="list-unstyled">
                          <strong> Note -</strong> 3-year Bachelor's degree with
                          honours, 4-year Bachelor's degree, or a Bachelor's
                          degree followed by a Master's degree (4 years of study
                          in total). A Post Graduate Diploma is not considered
                          equivalent to a Swedish kandidatexamen and does not
                          meet the requirement.
                        </li>
                        <li className="list-unstyled">
                          Please note that the following degrees do not satisfy
                          the entry requirements for a master's programme:
                        </li>
                        <li>
                          A Bachelor of Ayurvedic Medicine and Surgery from
                          India, Sri Lanka or Bangladesh
                        </li>
                        <li>
                          A Bachelor of Unani Medicine and Surgery from India,
                          Sri Lanka or Bangladesh
                        </li>
                        <li>
                          A Bachelor of Siddha Medicine & Surgery from India,
                          Sri Lanka or Bangladesh
                        </li>
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <p>
                  Refer this{" "}
                  <Link
                    to="https://www.universityadmissions.se/en/entry-requirements/"
                    target="_blank"
                  >
                    Link
                  </Link>{" "}
                  for more details on Entry Criteria.
                </p>
                <p>
                  <strong>Note -</strong> that the Application Office from
                  Sweden will send a verification mail to student’s Indian
                  University. Hence, student must inform their university in
                  advance or may speak to them in case it is required. If the
                  Verification is not completed, the student will not be
                  admitted despite being eligible and having a good profile.
                </p>
                <Table striped bordered hover size="sm">
                  <tbody>
                    <tr>
                      <td>Language Proficiency Required</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Bachelor and Master</td>
                      <td>
                        <strong>English 6:</strong> 6.5 (5.5)/ PTE 62 (Though it
                        is general requirement, however, it is recommended to
                        cross check University specific criteria as well)
                      </td>
                    </tr>
                    <tr>
                      <td>Important</td>
                      <td>
                        <li>
                          Students can apply for <strong>4 Programs</strong> at
                          one time under one{" "}
                          <strong>application fee i.e. 900 SEK.</strong>
                        </li>
                        <li>
                          Refer this{" "}
                          <Link
                            to="https://www.universityadmissions.se/en/apply-to-masters/rank-your-selections-masters/"
                            target="_blank"
                          >
                            link
                          </Link>{" "}
                          for more details.
                        </li>
                      </td>
                    </tr>
                    <tr>
                      <td>Tuition Fee Range</td>
                      <td>
                        <li>
                          <strong>Bachelor:</strong> 50,000 SEK/ Semester to
                          90,000 SEK/ Semester Approx. (depending upon Program)
                        </li>
                        <li>
                          <strong>Master:</strong> 60,000 SEK/ Semester to
                          1,00,000 SEK/ Semester Approx. (depending upon
                          Program)
                        </li>
                      </td>
                    </tr>
                    <tr>
                      <td>Tuition Fee Payment Format</td>
                      <td>
                        Semester Basis. Student need to pay One Semester Tuition
                        Fee before the Visa Application
                      </td>
                    </tr>
                    <tr>
                      <td>Documents Required for Application:</td>
                      <td>
                        <ul>
                          <li>Passport Copy</li>
                          <li>Academic Marksheets and Certificates</li>
                          <li>Language Proficiency IELTS/ TOEFL/ PTE</li>
                          <li>Experience Letter (if applicable)</li>
                          <li>CV (if applicable)</li>
                          <li>
                            Motivation Letter (Refer University Criteria for the
                            same)
                          </li>
                          <li>Letters of Recommendation (if applicable)</li>
                          <li>
                            Any Additional Documents (as per required by
                            University)
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>Accommodation</td>
                      <td>
                        Mostly Universities provide Accommodation through third
                        party. There is always a tough competition as the places
                        are limited. Hence student must secure their
                        accommodation immediately after the admission is
                        received.
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="6">
            <Accordion.Header>
              Visa Process – Checklist and Requirement
            </Accordion.Header>
            <Accordion.Body>
              <div>
                <Table striped bordered hover size="sm">
                  <tbody>
                    <tr>
                      <td>Residence Permit Processing Time</td>
                      <td>
                        30-60 days from the date of application submission
                        online.
                      </td>
                    </tr>
                    <tr>
                      <td>Residence Permit Fee</td>
                      <td>1500 SEK (to be paid to Swedish Immigration)</td>
                    </tr>
                    <tr>
                      <td>Insurance</td>
                      <td>Rs 14,000 approx.</td>
                    </tr>
                    <tr>
                      <td>Student’s Visa Application Deadline</td>
                      <td>Early May at the latest (Recommended)</td>
                    </tr>
                    <tr>
                      <td>Show Money for Students Residence Permit:</td>
                      <td>
                        Rs 10 Lacs approx. for one year of study in the main
                        applicant Account only (Joint Account not accepted).
                        Fresh funds accepted.
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <p>
                  <strong>Note -</strong>
                </p>
                <ol>
                  <li>Bank Certificate in the name of Student only.</li>
                  <li>
                    Bank Statement of last six months in the name of Student
                    only.
                  </li>
                </ol>
                <p>
                  <strong>Document Checklist -</strong>
                </p>
                <ul>
                  <li>Filled online form at migrationsverket</li>
                  <li>Valid Passport</li>
                  <li>
                    Passport Size Photograph (80% Face Coverage with white
                    background)
                  </li>
                  <li>Updated CV</li>
                  <li>
                    Scan Copy of All Pages of Passport including Cover Pages
                  </li>
                  <li>Acceptance Letter provided by University</li>
                  <li>Tuition Fee Receipt</li>
                  <li>Work Experience Certificate</li>
                  <li>Academic Documents</li>
                  <li>IELTS/PTE</li>
                  <li>Health Insurance</li>
                  <li>Updated Bank Certificate & Statement of Student</li>
                </ul>

                <p>
                  <strong>Note -</strong>
                </p>
                <ol>
                  <li>
                    The visa success in Sweden is highly based upon student’s
                    Embassy interview,
                  </li>
                  <li>
                    No gap is accepted, so kindly make sure that you have all
                    the sufficient documents for the same.
                  </li>
                </ol>
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="7">
            <Accordion.Header>
              Spouse Visa Checklist and Requirement
            </Accordion.Header>
            <Accordion.Body>
              <div>
                <Table striped bordered hover size="sm">
                  <tbody>
                    <tr>
                      <td>Residence Permit Processing Time</td>
                      <td>
                        30-60 days from the date of application submission
                        online.
                      </td>
                    </tr>
                    <tr>
                      <td>Residence Permit Fee</td>
                      <td>
                        1500 SEK approx. (to be paid to Swedish Immigration)
                      </td>
                    </tr>
                    <tr>
                      <td>Show Money</td>
                      <td>
                        Rs 9 Lacs approx. in the Spouse account: 1-day old fund
                        accepted with no source of income.
                        <ol>
                          <li>Bank certificate in the name of Spouse only.</li>
                          <li>
                            Bank Statement of last six months in the name of
                            Spouse only.
                          </li>
                        </ol>
                      </td>
                    </tr>
                    <tr>
                      <td>Document Checklist</td>
                      <td>
                        <ul>
                          <li>Filled online form at migrationsverket</li>
                          <li>Valid Passport</li>
                          <li>
                            Passport Size Photograph (80% Face Coverage with
                            white background)
                          </li>
                          <li>
                            Scan Copy of All Pages of Passport including Cover
                            Pages
                          </li>
                          <li>Insurance</li>
                          <li>Apostled Marriage Certificate</li>
                          <li>
                            Updated Bank Certificate & Statement of Spouse
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>CHILD Visa</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Residence Permit Processing Time</td>
                      <td>
                        30-60 days from the date of application submission
                        online.
                      </td>
                    </tr>
                    <tr>
                      <td>Show Money</td>
                      <td>
                        Rs 9 Lacs approx. in the Guardian account: 1-day old
                        fund accepted with no source of income.
                        <ol>
                          <li>
                            Bank certificate in the name of Guardian only.
                          </li>
                          <li>
                            Bank Statement of last six months in the name of
                            Guardian only.
                          </li>
                        </ol>
                      </td>
                    </tr>
                    <tr>
                      <td>Document Checklist</td>
                      <td>
                        <ul>
                          <li>Filled online form at migrationsverket</li>
                          <li>Valid Passport</li>
                          <li>
                            Passport Size Photograph (80% Face Coverage with
                            white background)
                          </li>
                          <li>
                            Scan Copy of All Pages of Passport including Cover
                            Pages
                          </li>
                          <li>Insurance</li>
                          <li>Apostled Marriage Certificate</li>
                          <li>
                            Updated Bank Certificate & Statement of Spouse
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="8">
            <Accordion.Header>Important Notes</Accordion.Header>
            <Accordion.Body>
              <div>
                <div className="py-2">
                  <ol>
                    <li>
                      Statement of Account for the past six months or from the
                      date the account was opened.{" "}
                      <strong>New Account is not accepted.</strong>
                    </li>
                    <li>
                      File submission will be online and there may be an
                      interview at Embassy discretion.
                    </li>
                    <li>
                      The whole family can apply at the same time, it is highly
                      recommended that Spouse and Child should apply for
                      Resident Permit after the main applicant reaches Sweden
                      and gets the Personal ID. This will make the file of
                      Applicant stronger,{" "}
                      <span className="text-danger">
                        else chances of refusal are 99%.
                      </span>
                    </li>
                  </ol>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </>
  );
};

export default SwedenComponent;
