import React from 'react'

export default function Studentapplicationuk() {
  return (
    <>
          <div className="accordion" id="accordionExample">
          <div className="accordion-item">
    <h2 className="accordion-header" id="headingFive">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
      Apply
      </button>
    </h2>
    <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
      <div className="accordion-body">
       <p>Make the application to the University for the preferred course</p>
      </div>
    </div>
  </div>

          <div className="accordion-item">
    <h2 className="accordion-header" id="headingSix">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
      Conditional offer
      </button>
    </h2>
    <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
      <div className="accordion-body">
       <p>University releases the conditional offer if the application is made on predicted score/ without English Language proficiency certificate.</p>
      </div>
    </div>
  </div>

          <div className="accordion-item">
    <h2 className="accordion-header" id="headingSeven">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
      Fulfill conditions
      </button>
    </h2>
    <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
      <div className="accordion-body">
       <p>Provide the required documents as per the conditions in the offer letter.</p>
       <p>Type of Conditions – Academics documents/English Language Test Score(IELTS)/Credibility Interview/Initial Deposit</p>
      </div>
    </div>
  </div>

          <div className="accordion-item">
    <h2 className="accordion-header" id="headingEight">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
      Unconditional offer
      </button>
    </h2>
    <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
      <div className="accordion-body">
       <p>When all the conditions are met then the unconditional offer is released</p>
      </div>
    </div>
  </div>

          <div className="accordion-item">
    <h2 className="accordion-header" id="headingNine">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
      Initial deposit
      </button>
    </h2>
    <div id="collapseNine" className="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample">
      <div className="accordion-body">
       <p>Initial deposit can be from £1000 onwards with up to 50% of the total tuition fees (depends upon the university’s policy). It may be one of the conditions and will be required to be paid prior to receiving an unconditional offer. The conditions vary as per the particular university criteria.</p>
      </div>
    </div>
  </div>

          <div className="accordion-item">
    <h2 className="accordion-header" id="headingTen">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
      Credibility Interview
      </button>
    </h2>
    <div id="collapseTen" className="accordion-collapse collapse" aria-labelledby="headingTen" data-bs-parent="#accordionExample">
      <div className="accordion-body">
       <p>Some universities may take the interview of the student either through telephone/skype/Recording (It may be held prior to the initial deposit or after the initial deposit – varies as per the university’s terms and conditions)</p>
      </div>
    </div>
  </div>

          <div className="accordion-item">
    <h2 className="accordion-header" id="headingEleven">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
      Financial check
      </button>
    </h2>
    <div id="collapseEleven" className="accordion-collapse collapse" aria-labelledby="headingEleven" data-bs-parent="#accordionExample">
      <div className="accordion-body">
       <p>Funds to the amount of tuition fee for one year (or balance amount) plus living expenses of one year.</p>
      </div>
    </div>
  </div>

          <div className="accordion-item">
    <h2 className="accordion-header" id="headingTwelwe">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwelwe" aria-expanded="false" aria-controls="collapseTwelwe">
      TB Certificate
      </button>
    </h2>
    <div id="collapseTwelwe" className="accordion-collapse collapse" aria-labelledby="headingTwelwe" data-bs-parent="#accordionExample">
      <div className="accordion-body">
       <p>Certain universities may ask for TB certificate (otherwise required at the time of visa filing)</p>
      </div>
    </div>
  </div>

          <div className="accordion-item">
    <h2 className="accordion-header" id="headingThirteen">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseNine">
      CAS (Confirmation of Acceptance of studies)
      </button>
    </h2>
    <div id="collapseThirteen" className="accordion-collapse collapse" aria-labelledby="headingThirteen" data-bs-parent="#accordionExample">
      <div className="accordion-body">
       <p>Confirmation of Acceptance of Studies is released when all the conditions are met – Academic/English Score/Financials</p>
      </div>
    </div>
  </div>

          <div className="accordion-item">
    <h2 className="accordion-header" id="headingForteen">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseForteen" aria-expanded="false" aria-controls="collapseForteen">
      Apply for Visa
      </button>
    </h2>
    <div id="collapseForteen" className="accordion-collapse collapse" aria-labelledby="headingForteen" data-bs-parent="#accordionExample">
      <div className="accordion-body">
       <p>After availing the CAS the student can apply for the Student Visa-PBS.</p>
      </div>
    </div>
  </div>


</div>
    </>
  )
}
